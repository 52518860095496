import {PlacementType, FullScreenSheetPlacement} from '@amzn/showtime';

import {getUriRegexString, JobDetailPlacementURI} from '../../utils/uriUtils/uriUtils';
import withProviders from '../Wrapper';

import JobDetailScreen from './JobDetailScreen';

export const id = JobDetailPlacementURI.id;
const uri = getUriRegexString(JobDetailPlacementURI);

const placement: FullScreenSheetPlacement = {
  component: withProviders(JobDetailScreen),
  id,
  rnwPath: uri,
  uri,
  type: PlacementType.FullScreenSheet,
  title: 'Job details',
};

export default placement;
