import React, {useCallback} from 'react';

import {CommonFilterOptions} from '../../common/filterOptions/constants/filterOption-constants';
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup';
import {useAppDispatch, useAppSelector} from '../../store';
import {REQUEST_SLICE_NAME} from '../../slices/constants/slice-constants';
import {setSorter} from '../../slices/request.slice';
import {FilterTitle} from '../../common/constant';

const DistanceSelection = () => {
  const dispatch = useAppDispatch();
  const sorter = useAppSelector(state => {
    return state[REQUEST_SLICE_NAME].sorter;
  });

  const handleOnSortByChange = useCallback(
    option => {
      dispatch(setSorter(option));
    },
    [dispatch],
  );

  return (
    <RadioButtonGroup
      selected={sorter}
      options={CommonFilterOptions.sortOptions}
      onChange={handleOnSortByChange}
      title={FilterTitle.SortBy}
    />
  );
};

export default DistanceSelection;
