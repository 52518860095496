import {
  CountryFullName,
  GeoUnit,
  Locale,
  PrivateScheduleKey,
  MaxDistance,
  JOB_CARDS_REQUEST_LIMIT,
} from '../common/constant';
import {
  StringBooleanKV,
  DateRangeKV,
  StringListKV,
  FloatRangeKV,
  SearchSorter,
  GeoQueryClause,
} from '../api/appSyncAPI/types';
import {
  FieldName,
  FilterKeys,
  SortOptionValues,
  StartDateOptionValue,
} from '../common/filterOptions/constants/filterOption-constants';
import {
  DistanceOption,
  LengthOfEmploymentOption,
  OptionType,
  ScheduleShifts,
  SortOption,
  WorkHourOptions,
} from '../common/filterOptions/types';
import {StartDateOptionType} from '../common/types';
import {getHoursPerWeekFromJobType, getStartDateRange} from '../common/utils';
import {RootState} from '../store';
import {selectFilterState} from '../common/hooks/useJobRequest';

export const generateFilterPayload = (key: FilterKeys, filterSelections: OptionType[]): StringListKV => {
  return filterSelections.reduce<{key: string; val: string[]}>(
    (acc, {value}) => {
      if (value) {
        acc.val.push(String(value));
      }
      return acc;
    },
    {key, val: []},
  );
};

const generateDateFilterPayload = (key: FilterKeys, startDate: StartDateOptionValue | null): DateRangeKV => {
  return {key, range: getStartDateRange(startDate)};
};

const generateEqualFilterPayload = (key: FilterKeys, val: string | boolean): StringBooleanKV => {
  return {
    key,
    val,
  };
};

const generateRangeFilterPayload = (key: FilterKeys, range: number[]): FloatRangeKV => {
  return {
    key,
    range: {
      minimum: range[0],
      maximum: range[1],
    },
  };
};

// TODO: Refactor to be filter agnostic
export const createFilters = ({
  scheduleShift = [],
  weekShifts = [],
  jobTitle = [],
  jobType = [],
  firstDayOnSite = [],
  employmentType,
  distance,
  sorter,
}: {
  scheduleShift?: ScheduleShifts;
  weekShifts?: ScheduleShifts;
  jobTitle?: OptionType[];
  jobType?: WorkHourOptions;
  firstDayOnSite?: StartDateOptionType[];
  employmentType?: LengthOfEmploymentOption;
  distance?: DistanceOption;
  sorter?: SortOption;
}) => {
  const containFilters: StringListKV[] = [];
  const dateFilters: DateRangeKV[] = [];
  const equalFilters: StringBooleanKV[] = [];
  const geoQueryClause: Partial<GeoQueryClause> = {
    distance: distance?.value || MaxDistance,
    unit: distance?.unit || GeoUnit.MILE,
  };
  const rangeFilters: FloatRangeKV[] = [];

  let sorters: SearchSorter[] = [];

  if (sorter?.value === SortOptionValues.PAY_RATE_HIGH_TO_LOW) {
    sorters = [{fieldName: FieldName.TOTAL_MAX_PAY_RATE, ascending: 'false'}];
  } else if (sorter?.value === SortOptionValues.PAY_RATE_LOW_TO_HIGH) {
    sorters = [{fieldName: FieldName.TOTAL_MAX_PAY_RATE, ascending: 'true'}];
  }

  if (scheduleShift.length || weekShifts.length) {
    const result: StringListKV = generateFilterPayload(FilterKeys.SCHEDULE_SHIFTS, [...scheduleShift, ...weekShifts]);

    containFilters.push(result);
  }

  if (jobTitle.length) {
    const result: StringListKV = generateFilterPayload(FilterKeys.JOB_TITLE, jobTitle);

    containFilters.push(result);
  }

  if (jobType.length) {
    const result: FloatRangeKV = generateRangeFilterPayload(
      FilterKeys.HRS_PER_WEEK,
      getHoursPerWeekFromJobType(jobType[0].value),
    );

    rangeFilters.push(result);
  }

  if (firstDayOnSite.length) {
    for (const startDate of firstDayOnSite) {
      const result: DateRangeKV = generateDateFilterPayload(FilterKeys.FIRST_DAY_ON_SITE, startDate.value);

      dateFilters.push(result);
    }
  }

  if (employmentType && employmentType.value) {
    equalFilters.push(generateEqualFilterPayload(FilterKeys.EMPLOYMENT_TYPE, employmentType.value));
  }

  return {
    containFilters,
    dateFilters,
    equalFilters,
    geoQueryClause,
    rangeFilters,
    sorters,
  };
};

// TODO: Replace filter type
export const jobSearchRequestGenerator = ({
  location,
  keywords = '',
  equalFilters = [],
  dateFilters = [],
  containFilters = [],
  orFilters = [],
  rangeFilters = [],
  excludeFilters = [],
  geoQueryClause,
  sorters = [],
  pageSize = JOB_CARDS_REQUEST_LIMIT,
  nextToken,
}: {
  location?: {lat: number; lng: number};
  keywords?: string;
  sort?: {value: SortOptionValues};
  equalFilters?: StringBooleanKV[];
  dateFilters?: DateRangeKV[];
  containFilters?: StringListKV[];
  orFilters?: StringListKV[];
  rangeFilters?: FloatRangeKV[];
  excludeFilters?: StringListKV[];
  geoQueryClause?: Partial<GeoQueryClause>;
  sorters?: SearchSorter[];
  pageSize?: number;
  nextToken?: string;
}) => {
  let geoQuery = undefined;

  if (location && location.lat && location.lng) {
    geoQuery = {
      lat: location.lat,
      lng: location.lng,
      unit: geoQueryClause?.unit || GeoUnit.MILE,
      distance: geoQueryClause?.distance || MaxDistance,
    };
  }

  if (!dateFilters.find(filter => filter.key === FilterKeys.FIRST_DAY_ON_SITE)) {
    const result: DateRangeKV = generateDateFilterPayload(FilterKeys.FIRST_DAY_ON_SITE, null);

    dateFilters.push(result);
  }

  return {
    locale: Locale.US,
    country: CountryFullName.US,
    keyWords: keywords.trim(),
    equalFilters,
    containFilters: [
      ...containFilters,
      {
        key: PrivateScheduleKey,
        val: ['false'],
      },
    ],
    rangeFilters,
    orFilters,
    dateFilters,
    excludeFilters,
    sorters,
    geoQueryClause: geoQuery,
    pageSize,
    nextToken,
  };
};

export const createJobRequestInEpic = (state: RootState, filterOverride = {}) => {
  const {
    distance,
    employmentType,
    firstDayOnSite,
    jobTitle,
    jobType,
    keywords,
    location,
    scheduleShift,
    sorter,
    weekShifts,
  } = selectFilterState(state);

  const {dateFilters, containFilters, equalFilters, geoQueryClause, sorters, rangeFilters} = createFilters({
    weekShifts,
    scheduleShift,
    jobTitle,
    jobType,
    firstDayOnSite,
    employmentType,
    distance,
    sorter,
  });

  const request = {
    rangeFilters,
    containFilters,
    dateFilters,
    equalFilters,
    keywords,
    location,
    geoQueryClause,
    sorters,
    ...filterOverride,
  };

  return jobSearchRequestGenerator(request);
};
