import * as React from 'react';
import Svg, {SvgProps, Circle, Path} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={56} height={57} fill="none" {...props}>
    <Circle cx={28} cy={28.749} r={28} fill="#D7EBFB" />
    <Path
      stroke="#203040"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M21.702 22.112a4.374 4.374 0 1 0 0-8.747 4.374 4.374 0 0 0 0 8.747ZM15.577 29.986v2.935a2.666 2.666 0 0 0 2.666 2.666h1.707v3.674h-5.034a2.666 2.666 0 0 1-2.666-2.666v-9.087a3.295 3.295 0 0 1 3.293-3.293h3.531l1.6 2.4a1.473 1.473 0 0 0 2.434.026l1.564-2.25h.942a3.295 3.295 0 0 1 3.294 3.294v1.431"
    />
    <Path
      stroke="#203040"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M46.375 29.113H28.701l-2.8 12.395h17.675l2.799-12.395ZM20.127 36.14h3c1.09 0 1.973.884 1.973 1.972v1.067h-4.973v-3.04Z"
    />
    <Path
      stroke="#203040"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M36.138 36.554a.967.967 0 1 0 0-1.934.967.967 0 0 0 0 1.934Z"
    />
  </Svg>
);

export default SvgComponent;
