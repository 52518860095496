import * as React from 'react';
import Svg, {SvgProps, Circle, Path} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={56} height={56} fill="none" {...props}>
    <Circle cx={28} cy={28} r={28} fill="#D7EBFB" />
    <Path stroke="#161E2D" d="M15.787 12.738h25.16v6.474h-25.16zM15.787 22.709h25.16v6.474h-25.16z" />
    <Path
      fill="#D7EBFB"
      d="m34.406 47.25 1.84-5.732v-7.733a1.641 1.641 0 0 0-1.64-1.64 1.653 1.653 0 0 0-1.656 1.64v-.672a1.647 1.647 0 0 0-2.807-1.162 1.647 1.647 0 0 0-.483 1.162v-.49a1.647 1.647 0 0 0-3.294 0v-4.934a1.65 1.65 0 0 0-1.643-1.647 1.65 1.65 0 0 0-1.647 1.647v10.085l-1.361-2.76a1.867 1.867 0 0 0-3.53 1.16l1.822 6.672 3.195 4.403"
    />
    <Path
      stroke="#2C3746"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m34.406 47.25 1.84-5.732v-7.733a1.641 1.641 0 0 0-1.64-1.64 1.653 1.653 0 0 0-1.656 1.64v-.672a1.647 1.647 0 0 0-2.807-1.162 1.647 1.647 0 0 0-.483 1.162v-.49a1.647 1.647 0 0 0-3.294 0v-4.934a1.65 1.65 0 0 0-1.643-1.647 1.65 1.65 0 0 0-1.647 1.647v10.085l-1.361-2.76a1.867 1.867 0 0 0-3.53 1.16l1.822 6.672 3.195 4.403"
    />
    <Path stroke="#000" d="m18.276 15.702 1.223 1.222 1.833-2.444M18.276 25.674l1.223 1.222 1.833-2.444" />
  </Svg>
);

export default SvgComponent;
