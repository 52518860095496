import React, {useCallback, useMemo} from 'react';
import {LinkCard} from '@amzn/stencil-react-native-components/card';
import {Col, Row} from '@amzn/stencil-react-native-components/layout';
import {Text} from '@amzn/stencil-react-native-components/text';
import {
  StencilFontSizeEnum,
  StencilFontWeightEnum,
  StencilSpacingEnum,
} from '@amzn/stencil-react-native-components/types';
import {useActions} from '@amzn/showtime';

import {JobCard} from '../../common/types';
import {mapJobTitleDisplayValue} from '../../utils/commonHelper';
import {
  getBannerData,
  getEmploymentTypeText,
  getJobTypeText,
  getPayRateTextWithPrefix,
  isDragonStoneJobOrNot,
  isRemoteJob,
  renderJobCardDistanceContent,
} from '../../utils/JobCardHelper/JobCardHelper';
import JobCardChip from '../JobCardChip/JobCardChip';
import {BorderEnum, GeoUnit, MetricNames, NotApplicable, RemoteLocation} from '../../common/constant';
import {CommonColors} from '../../common/color';
import {getUri, JobDetailPlacementURI, getRedirectCareerSiteUri} from '../../utils/uriUtils/uriUtils';
import {emitMetric} from '../../utils/metricsHelper';
import {useIsJobDetailPageEnabled} from '../../features';

interface JobCardProps {
  jobCard: JobCard;
  indexPosition: number;
}

const JobCardComponent = (props: JobCardProps) => {
  const {pushUri} = useActions();
  const {jobCard, indexPosition} = props;
  const {
    jobTitle,
    scheduleCount,
    employmentType,
    dataSource,
    jobType,
    jobId,
    totalPayRateMin,
    totalPayRateMax,
    currencyCode,
    virtualLocation,
    jobContainerJobMetaL1,
  } = jobCard;

  const isDragonStoneJob = useMemo(() => isDragonStoneJobOrNot(dataSource), [dataSource]);

  const jobTypeText = useMemo(() => getJobTypeText(jobType), [jobType]);

  const employmentTypeText = useMemo(() => getEmploymentTypeText(employmentType), [employmentType]);

  const getDistanceText = useCallback((): string => {
    const {locationInfo, distanceText, roundedDistanceShown} = renderJobCardDistanceContent(jobCard, GeoUnit.MILE);

    if (isRemoteJob(dataSource, virtualLocation, jobContainerJobMetaL1)) return RemoteLocation;
    if (roundedDistanceShown === NotApplicable) return locationInfo;
    if (locationInfo === '') return `${isDragonStoneJob ? `Within ${distanceText}` : `${distanceText}`}`;

    return `${isDragonStoneJob ? `Within ${distanceText} | ${locationInfo}` : `${distanceText} | ${locationInfo}`}`;
  }, [isDragonStoneJob, jobCard, dataSource, virtualLocation, jobContainerJobMetaL1]);

  const isJobDetailPageEnabled = useIsJobDetailPageEnabled();

  const bannerData = useMemo(() => getBannerData(jobCard), [jobCard]);
  const jobTitleDisplay = useMemo(() => mapJobTitleDisplayValue(jobTitle), [jobTitle]);
  const payRateDisplay = useMemo(
    () => getPayRateTextWithPrefix(totalPayRateMin, totalPayRateMax, currencyCode),
    [totalPayRateMin, totalPayRateMax, currencyCode],
  );

  const onPressRedirectToCareerSiteJobDetail = useCallback(() => {
    emitMetric(
      MetricNames.UserActionJobCardClick,
      JSON.stringify({
        jobTitle: jobTitleDisplay,
        payRate: payRateDisplay,
        distance: getDistanceText(),
        jobType: jobTypeText,
        employmentType: employmentTypeText,
        ...(bannerData && {banner: bannerData}),
        ...(isDragonStoneJob && scheduleCount && {scheduleCount}),
      }),
      {ElementIndex: indexPosition},
    );

    if (isJobDetailPageEnabled) {
      pushUri(getUri(JobDetailPlacementURI, {jobId}));
    } else {
      pushUri(getRedirectCareerSiteUri('app#/jobDetail', {jobId}));
    }
  }, [
    jobTitleDisplay,
    payRateDisplay,
    getDistanceText,
    jobTypeText,
    employmentTypeText,
    bannerData,
    isDragonStoneJob,
    scheduleCount,
    indexPosition,
    isJobDetailPageEnabled,
    pushUri,
    jobId,
  ]);

  return (
    <LinkCard
      accessible
      accessibilityRole="link"
      accessibilityLabel={`Job Card Link for ${jobTitleDisplay} ${payRateDisplay} ${
        bannerData ? bannerData : ''
      } ${getDistanceText()}} ${jobTypeText + ' - ' + employmentTypeText}`}
      onPress={onPressRedirectToCareerSiteJobDetail}
      testID={'JobCard-' + jobId}
      backgroundColor={CommonColors.White}
      style={{
        minHeight: 193,
        borderStyle: BorderEnum.SOLID,
      }}
      borderColor={CommonColors.Neutral20}
      borderWidth={1}
    >
      <Col accessible gridGap={StencilSpacingEnum.S200}>
        <Row>
          <Text fontWeight={StencilFontWeightEnum.Bold} fontSize={StencilFontSizeEnum.T400}>
            {jobTitleDisplay}
          </Text>
        </Row>
        <Row>
          <Text fontSize={StencilFontSizeEnum.T400}>{payRateDisplay}</Text>
        </Row>
        <Row gridGap={StencilSpacingEnum.S200}>
          {isDragonStoneJob && scheduleCount ? (
            <JobCardChip
              chipColor={CommonColors.Neutral05}
              text={`${scheduleCount} ${scheduleCount > 1 ? 'shifts' : 'shift'} available`}
            />
          ) : null}
          {bannerData ? <JobCardChip chipColor={CommonColors.Blue10} text={bannerData} /> : null}
        </Row>
        <Row>
          <Text color={CommonColors.Neutral70}>{getDistanceText()}</Text>
        </Row>
        <Row>
          <Text color={CommonColors.Neutral70}>{jobTypeText + ' - ' + employmentTypeText}</Text>
        </Row>
      </Col>
    </LinkCard>
  );
};

export default JobCardComponent;
