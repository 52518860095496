import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import {JobDetail} from '../common/types';

import {JOB_DETAIL_SLICE_NAME} from './constants/slice-constants';

export interface JobDetailState {
  fetchingJobDetail: boolean;
  jobDetail?: JobDetail;
}

export const initialJobDetailState: JobDetailState = {
  fetchingJobDetail: false,
};

const {actions, reducer} = createSlice({
  name: JOB_DETAIL_SLICE_NAME,
  initialState: initialJobDetailState,
  reducers: {
    resetJobDetailState: () => {
      return initialJobDetailState;
    },
    fetchJobDetail: state => {
      state.fetchingJobDetail = true;
    },
    onReceiveJobDetail: (state: JobDetailState, {payload: jobDetail}: PayloadAction<JobDetail>) => {
      state.jobDetail = jobDetail;
      state.fetchingJobDetail = false;
    },
  },
});

export const {resetJobDetailState, fetchJobDetail, onReceiveJobDetail} = actions;

export default reducer;
