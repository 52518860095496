import {FullScreenSheetPlacement, PlacementType, withErrorBoundary} from '@amzn/showtime';

import {FilterScreenPlacementURI, getUriRegexString} from '../../utils/uriUtils/uriUtils';
import withProviders from '../../placements/Wrapper';
import {emitMetric} from '../../utils/metricsHelper';
import {MetricNames} from '../../common/constant';

import {FilterScreen} from './FilterScreen';

export const id = FilterScreenPlacementURI.id;
const uri = getUriRegexString(FilterScreenPlacementURI);

const placement: FullScreenSheetPlacement = {
  component: withProviders(withErrorBoundary(FilterScreen)),
  id,
  uri,
  rnwPath: uri,
  title: 'Filter',
  type: PlacementType.FullScreenSheet,
  onCloseCompletion: () => {
    emitMetric(MetricNames.UserActionFilterScreenClose);
  },
};

export default placement;
