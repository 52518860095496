import * as React from 'react';
import Svg, {SvgProps, Circle, Path} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={56} height={57} fill="none" {...props}>
    <Circle cx={28} cy={28.749} r={28} fill="#D7EBFB" />
    <Path
      stroke="#203040"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M39.324 25.536a6.706 6.706 0 1 0 0-13.412 6.706 6.706 0 0 0 0 13.412Z"
    />
    <Path
      stroke="#203040"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m35.939 18.684 2.708 2.602 4.758-4.953M31.583 20.14H13.125v21.734h9.963v-8.692h6.058v8.692h10.12V26.737m-2.593-1.002v2.931H15.718v-6.122h17.75M20.494 36.522h-4.693v-3.344h4.693v3.344Zm16.258 0H31.74v-3.344h5.013v3.344Z"
    />
  </Svg>
);

export default SvgComponent;
