import * as React from 'react';
import Svg, {SvgProps, Circle, G, Path, Defs, ClipPath} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={56} height={56} fill="none" {...props}>
    <Circle cx={28} cy={28} r={28} fill="#D7EBFB" />
    <G stroke="#232F3E" clipPath="url(#a)">
      <Path
        strokeMiterlimit={10}
        d="M35.526 22.366c2.771 0 5.018-2.22 5.018-4.958s-2.247-4.957-5.018-4.957c-2.77 0-5.017 2.22-5.017 4.957 0 2.738 2.246 4.958 5.017 4.958Z"
      />
      <Path strokeMiterlimit={10} d="M35.526 22.366v18.48H18.193V17.407H30.508" />
      <Path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M38.263 21.564v21.084a.907.907 0 0 1-.912.901H16.369a.907.907 0 0 1-.913-.901V15.606c0-.498.408-.902.913-.902H31.32M20.93 21.916h11.86M20.93 24.62h11.86M20.93 27.324h11.86M20.93 30.028h5.474"
      />
      <Path strokeLinecap="round" strokeMiterlimit={10} d="m32.79 17.86 1.824 1.802 3.65-3.606" />
    </G>
    <Defs>
      <ClipPath id="a">
        <Path fill="#fff" d="M15 12h26v32H15z" />
      </ClipPath>
    </Defs>
  </Svg>
);

export default SvgComponent;
