import React, {useCallback} from 'react';

import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import {useAppDispatch, useAppSelector} from '../../store';
import {REQUEST_SLICE_NAME} from '../../slices/constants/slice-constants';
import {setJobTitle} from '../../slices/request.slice';
import {OptionType} from '../../common/filterOptions/types';
import {USJobRoles} from '../../common/filterOptions/constants/filterOption-constants';
import {FilterTitle} from '../../common/constant';

export const DepartmentSelection = () => {
  const dispatch = useAppDispatch();
  const jobTitle = useAppSelector(state => {
    return state[REQUEST_SLICE_NAME].jobTitle;
  });

  const handleOnChange = useCallback(
    (options: OptionType[]) => {
      dispatch(setJobTitle(options));
    },
    [dispatch],
  );

  return (
    <CheckboxGroup
      selectedValues={jobTitle}
      options={USJobRoles}
      onChange={handleOnChange}
      title={FilterTitle.Department}
    />
  );
};

export default React.memo(DepartmentSelection);
