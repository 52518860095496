import {Tenant, TenantBootstrapProps, TenantBootstrapFunc} from '@amzn/showtime';

import atozTenantId from './TenantId';
import mobileTenantConfig from './MobileTenantConfig';
import getPlacements from './placements';
import postRegister from './postRegister';

function bootstrapTenant(props: TenantBootstrapProps): Tenant {
  const {features} = props;

  const placements = getPlacements(features);

  return {
    atozTenantId,
    mobileTenantConfig,
    placements,
    postRegister,
  };
}

export default bootstrapTenant as TenantBootstrapFunc;
