import React, {ReactElement} from 'react';
import {Col} from '@amzn/stencil-react-native-components/layout';
import {Text} from '@amzn/stencil-react-native-components/text';
import {StencilFontSizeEnum, StencilFontWeightEnum} from '@amzn/stencil-react-native-components/types';

const JobNotFound = (): ReactElement => {
  return (
    <Col alignItems="center" gridGap="S200" margin={{top: 'S600', bottom: 'S600'}}>
      <Text fontWeight={StencilFontWeightEnum.Bold} fontSize={StencilFontSizeEnum.T400} textAlign="center">
        No results match your search
      </Text>
      <Text textAlign="center">
        Refine your search to ensure you&#39;re seeing all the available jobs in your area.
      </Text>
    </Col>
  );
};

export default JobNotFound;
