import {ApolloQueryResult, gql} from '@apollo/client';

import {getApolloClient} from '../../apollo';
import {MetricNames} from '../../common/constant';
import {wrapApiWithMetrics} from '../../utils/metricsHelper';

import {
  queryGeoInfoByAddressQuery,
  searchJobCardsByLocationQuery,
  getJobDetailQuery,
  searchScheduleCardsQuery,
} from './queries';
import {
  GeoAddressQueryRequest,
  GetJobDetailRequest,
  GetJobDetailResponseGQL,
  QueryGeoInfoByAddressResponseGQL,
  SearchJobCardsByLocationRequest,
  SearchJobCardsByLocationResponseGQL,
  SearchScheduleRequest,
  SearchScheduleResponseGQL,
} from './types';

export const queryGeoInfoByAddress = async (
  geoAddressQueryRequest: GeoAddressQueryRequest,
): Promise<ApolloQueryResult<QueryGeoInfoByAddressResponseGQL>> => {
  const apiFunc = async () => {
    const client = await getApolloClient();

    return client.query({query: gql(queryGeoInfoByAddressQuery), variables: {geoAddressQueryRequest}});
  };

  return wrapApiWithMetrics(MetricNames.ApiQueryGeoInfoByAddress, apiFunc, geoAddressQueryRequest);
};

export const searchJobCardsByLocation = async (
  searchJobRequest: SearchJobCardsByLocationRequest,
): Promise<ApolloQueryResult<SearchJobCardsByLocationResponseGQL>> => {
  const apiFunc = async () => {
    const client = await getApolloClient();

    return client.query({query: gql(searchJobCardsByLocationQuery), variables: {searchJobRequest}});
  };

  return wrapApiWithMetrics(MetricNames.ApiSearchJobCardsByLocation, apiFunc, searchJobRequest);
};

export const getJobDetails = async (
  getJobDetailRequest: GetJobDetailRequest,
): Promise<ApolloQueryResult<GetJobDetailResponseGQL>> => {
  const apiFunc = async () => {
    const client = await getApolloClient();

    return client.query({query: gql(getJobDetailQuery), variables: {getJobDetailRequest}});
  };

  return wrapApiWithMetrics(MetricNames.ApiGetJobDetail, apiFunc);
};

export const searchScheduleCards = async (
  searchScheduleRequest: SearchScheduleRequest,
): Promise<ApolloQueryResult<SearchScheduleResponseGQL>> => {
  const apiFunc = async () => {
    const client = await getApolloClient();

    return client.query({query: gql(searchScheduleCardsQuery), variables: {searchScheduleRequest}});
  };

  return wrapApiWithMetrics(MetricNames.ApiSearchScheduleCards, apiFunc);
};
