import {Metric, Trace, Attributes} from '@amzn/showtime';
import {GraphQLError} from 'graphql/error';

import {MetricNames} from '../common/constant';
import {logging} from '../logger';
import tenantId from '../TenantId';
import {GeoAddressQueryRequest, SearchJobCardsByLocationRequest} from '../api/appSyncAPI/types';

export const wrapSubSectionCallWithTrace = (metricName: MetricNames, callable: any): void => {
  const trace = Trace(metricName, tenantId);

  try {
    return callable();
  } catch (err: any) {
    handleErrorMetrics(metricName, trace, err);
    throw err;
  } finally {
    trace.log();
  }
};

export const wrapApiWithMetrics = async <T>(
  metricName: MetricNames,
  callable: () => Promise<any>,
  request?: any,
): Promise<T> => {
  const trace = Trace(metricName, tenantId);

  mergeApiRequestAttributes(metricName, trace, request);

  try {
    const response = await callable();

    if (response?.error || response?.errors?.length) {
      handleErrorMetrics(metricName, trace, response);
      return Promise.reject(response);
    }
    return Promise.resolve(response);
  } catch (err: any) {
    handleErrorMetrics(metricName, trace, err);
    return Promise.reject(err);
  } finally {
    trace.log();
  }
};

const mergeApiRequestAttributes = (metricName: MetricNames, trace: any, request?: any): void => {
  let req;

  switch (metricName) {
    case MetricNames.ApiSearchJobCardsByLocation:
      req = request as SearchJobCardsByLocationRequest;
      trace.mergeAttributes({
        keyWords: req?.keyWords ?? '',
        locale: req?.locale ?? '',
        country: req?.country ?? '',
      });
      break;
    case MetricNames.ApiQueryGeoInfoByAddress:
      req = request as GeoAddressQueryRequest;
      trace.mergeAttributes({
        address: req?.address ?? '',
        countries: req?.countries?.length > 0 ? req.countries.join(',') : '',
      });
      break;
    case MetricNames.ApiQueryLatestJobAddress:
    default:
    // No Op
  }
};

const handleErrorMetrics = (metricName: MetricNames, trace: any, response: any): any => {
  if (response.message) {
    // If this is a plain exception
    trace.mergeAttributes({
      error: response.message,
    });
  } else if (response.error) {
    // if this is an ApolloQueryResult with Error Message
    trace.mergeAttributes({
      error: response.error,
    });
  } else if (response.errors?.length) {
    // if this is an ApolloQueryResult with Errors Array
    trace.mergeAttributes({
      error: (response.errors as ReadonlyArray<GraphQLError>).map((e: GraphQLError) => e.toJSON().message).join(','),
    });
  }
  Metric(`${metricName}Error`, tenantId).put();
  logging.error(`${metricName}Error` + trace.error);
};

export const emitMetric = (metricName: MetricNames | string, data = '', attributes: Attributes = {}) => {
  Metric(metricName, tenantId, {attributes: {Element: data, ...attributes}}).put();
};
