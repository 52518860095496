import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {MessageType} from '@amzn/stencil-react-native-components/banners';

import {ALERTS_SLICE_NAME} from './constants/slice-constants';
export interface Alert {
  alertType?: MessageType;
  alertMessage: string;
}

export interface AlertsState {
  alerts: Alert[];
}

export const initialAlertsState: AlertsState = {
  alerts: [],
};

const {actions, reducer} = createSlice({
  name: ALERTS_SLICE_NAME,
  initialState: initialAlertsState,
  reducers: {
    addAlert: (state, {payload}: PayloadAction<Alert>) => {
      state.alerts.push(payload);
    },
    resetAlertsState: () => {
      return initialAlertsState;
    },
  },
});

export const {addAlert, resetAlertsState} = actions;

export default reducer;
