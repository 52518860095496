import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={20} height={20} fill="none" {...props}>
    <Path
      stroke="#232F3E"
      strokeWidth={1.5}
      d="M13.782 12.293c-.724 1.353-1.684 2.866-2.773 4.58l-.797 1.26a.25.25 0 0 1-.423 0L9 16.89l-.01-.016c-1.089-1.714-2.049-3.227-2.773-4.58-.9-1.68-1.468-3.165-1.468-4.542C4.75 4.975 7.252 2.75 10 2.75s5.25 2.225 5.25 5.001c0 1.377-.567 2.861-1.468 4.542ZM10 10.75a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5Z"
    />
  </Svg>
);

export default SvgComponent;
