import {concat, from, Observable, of} from 'rxjs';
import {catchError, filter, switchMap, withLatestFrom} from 'rxjs/operators';
import {ApolloQueryResult} from '@apollo/client';
import {createAction} from '@reduxjs/toolkit';
import {StateObservable} from 'redux-observable';

import {getJobDetails} from '../api/appSyncAPI/appSyncApi';
import {GetJobDetailResponseGQL} from '../api/appSyncAPI/types';
import {fetchJobDetail, onReceiveJobDetail} from '../slices/jobDetail.slice';
import {RootState} from '../slices/root.slice';
import {Locale} from '../common/constant';
import {addAlert} from '../slices/alerts.slice';

export enum JobDetailActionTypes {
  GET_JOB_DETAIL = 'jobDetail/getJobDetail',
}

export interface getJobDetailPayload {
  jobId: string;
}

export const getJobDetailAction = createAction<getJobDetailPayload>(JobDetailActionTypes.GET_JOB_DETAIL);

export const getJobDetailEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(getJobDetailAction.match),
    withLatestFrom(state$),
    switchMap(([actions]: [any, RootState]): Observable<any> => {
      return concat(
        of(fetchJobDetail()),
        from(getJobDetails({jobId: actions.payload.jobId, locale: Locale.US})).pipe(
          switchMap(async (response: ApolloQueryResult<GetJobDetailResponseGQL>) => {
            if (!response?.data?.getJobDetail) {
              throw new Error('Unable to retrieve job details');
            }
            return onReceiveJobDetail(response.data.getJobDetail);
          }),
        ),
      );
    }),
    catchError(() => {
      return [addAlert({alertMessage: 'Unable to retrieve job details'})];
    }),
  );
