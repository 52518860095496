import {createSlice} from '@reduxjs/toolkit';

import {LOCATION_SLICE_NAME} from './constants/slice-constants';
import {RootState} from './root.slice';

export interface LocationState {
  lat: number;
  lng: number;
  address: string;
  defaultAddressLoaded: boolean;
  fetchingDefaultAddress: boolean;
}

export const initialLocationState: LocationState = {
  lat: 0,
  lng: 0,
  address: '',
  defaultAddressLoaded: false,
  fetchingDefaultAddress: false,
};

export const selectLocationState = (state: RootState) => state[LOCATION_SLICE_NAME];

const {actions, reducer} = createSlice({
  name: LOCATION_SLICE_NAME,
  initialState: initialLocationState,
  reducers: {
    receiveGeoInfoByLocationType: (state, {payload: {lat, lng}}) => {
      state.lat = lat;
      state.lng = lng;
    },
    setAddress: (state, {payload: {address}}) => {
      state.address = address;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    fetchDefaultAddress: (state, payload) => {
      state.fetchingDefaultAddress = true;
    },
    receiveDefaultAddress: (state, {payload: {address}}) => {
      state.address = address;
      state.defaultAddressLoaded = true;
      state.fetchingDefaultAddress = false;
    },
    resetLocationState: () => {
      return initialLocationState;
    },
  },
});

export const {
  receiveGeoInfoByLocationType,
  setAddress,
  fetchDefaultAddress,
  receiveDefaultAddress,
  resetLocationState,
} = actions;

export default reducer;
