import * as React from 'react';
import Svg, {SvgProps, Circle, Path} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={56} height={56} fill="none" {...props}>
    <Circle cx={28} cy={28} r={28} fill="#D7EBFB" />
    <Path
      stroke="#232F3E"
      strokeMiterlimit={10}
      d="M29.861 20.862h5.325c.524 0 .949.422.949.946v23.728c0 .524-.425.949-.95.949H20.95a.949.949 0 0 1-.949-.95v-23.72c0-.524.425-.949.95-.949h5.326"
    />
    <Path fill="#232F3E" d="M31.864 26.082V38.42H24.27V26.082h7.593Zm.949-.95h-9.491V39.37h9.49V25.133Z" />
    <Path stroke="#232F3E" strokeLinecap="round" strokeLinejoin="round" d="M23.796 41.742h8.542" />
    <Path
      fill="#232F3E"
      d="M29.017 16.59v4.746h-1.899v-4.745h1.899Zm0-.948h-1.899a.949.949 0 0 0-.949.949v4.745c0 .525.425.95.95.95h1.898c.524 0 .949-.425.949-.95v-4.745a.949.949 0 0 0-.95-.95Z"
    />
    <Path stroke="#232F3E" strokeMiterlimit={10} d="M28.067 16.116V9" />
  </Svg>
);

export default SvgComponent;
