import {Flex, Radio} from '@amzn/atoz-native';
import React, {useCallback, useMemo, useState} from 'react';

import {OptionType} from '../../common/filterOptions/types';
import {emitMetric} from '../../utils/metricsHelper';
import {MetricNames} from '../../common/constant';
import {camelize} from '../../utils/commonHelper';

import styles from './styles';

export enum RadioButtonGroupLayout {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

interface RadioButtonGroupProps {
  options: OptionType[];
  onChange?: (value: OptionType) => void;
  layout?: string;
  selected?: OptionType;
  title: string;
}

const RadioButtonGroup = ({options, onChange, layout, selected, title}: RadioButtonGroupProps): JSX.Element => {
  const [selectedOption, setSelectedOption] = useState(options[0]);

  const selection = useMemo(() => {
    return selected ? selected : selectedOption;
  }, [selected, selectedOption]);

  const handleOptionChange = useCallback(
    (option: OptionType) => {
      emitMetric(`${MetricNames.UserActionFilterSelected}.${camelize(title, true)}`, option.shownValue);

      setSelectedOption(option);

      onChange!(option);
    },
    [onChange, title],
  );

  return (
    <Flex
      testID="radio-button-group"
      style={[
        styles.container,
        layout === RadioButtonGroupLayout.HORIZONTAL ? styles.horizontalContainer : styles.verticalContainer,
      ]}
      gridGap="S300"
    >
      {options.map((option: OptionType) => {
        return (
          <Radio
            accessibilityLabel={option.shownValue}
            labelText={option.shownValue}
            checked={selection.value === option.value}
            key={option.shownValue}
            onChange={() => handleOptionChange(option)}
          />
        );
      })}
    </Flex>
  );
};

export const defaultProps: Partial<RadioButtonGroupProps> = {
  layout: RadioButtonGroupLayout.VERTICAL,
  onChange: () => {},
};

RadioButtonGroup.defaultProps = defaultProps;

export default RadioButtonGroup;
