import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={21} fill="none" {...props}>
    <Path
      fill="#232F3E"
      fillRule="evenodd"
      d="M16 2H8v2h8V2ZM6 2v2H2a2 2 0 0 0-2 2v13a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2h-4V2a2 2 0 0 0-2-2H8a2 2 0 0 0-2 2Zm2 4h14v3H2V6h6Zm-6 5v8h20v-8H2Z"
      clipRule="evenodd"
    />
  </Svg>
);

export default SvgComponent;
