import React, {useCallback} from 'react';

import {USLengthOfEmploymentOptions} from '../../common/filterOptions/constants/filterOption-constants';
import {useAppDispatch, useAppSelector} from '../../store';
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup';
import {setEmploymentType} from '../../slices/request.slice';
import {REQUEST_SLICE_NAME} from '../../slices/constants/slice-constants';
import {FilterTitle} from '../../common/constant';

const EmploymentTypeSelection = () => {
  const dispatch = useAppDispatch();
  const employmentType = useAppSelector(state => {
    return state[REQUEST_SLICE_NAME].employmentType;
  });

  const handleOnEmploymentTypeChange = useCallback(
    option => {
      dispatch(setEmploymentType(option));
    },
    [dispatch],
  );

  return (
    <RadioButtonGroup
      options={USLengthOfEmploymentOptions}
      selected={employmentType}
      onChange={handleOnEmploymentTypeChange}
      title={FilterTitle.LengthOfEmployment}
    />
  );
};

export default EmploymentTypeSelection;
