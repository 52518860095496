import React from 'react';
import {Col, Row} from '@amzn/stencil-react-native-components/layout';
import {Text} from '@amzn/stencil-react-native-components/text';
import {StencilSpacingEnum} from '@amzn/stencil-react-native-components/types';

import IconHiringProcessApply from '../../components/Icons/IconHiringProcessApply';
import IconHiringProcessFirstDay from '../../components/Icons/IconHiringProcessFirstDay';
import IconHiringProcessOrientation from '../../components/Icons/IconHiringProcessOrientation';
import IconHiringProcessPrehire from '../../components/Icons/IconHiringProcessPrehire';
import IconCSHiringProcessApply from '../../components/Icons/IconCSHiringProcessApply';
import IconCSHiringProcessAssessment from '../../components/Icons/IconCSHiringProcessAssessment';
import IconCSHiringProcessOrientation from '../../components/Icons/IconCSHiringProcessOrientation';
import IconCSHiringProcessFirstDay from '../../components/Icons/IconCSHiringProcessFirstDay';
import {CommonColors} from '../../common/color';
import {isCustomerServiceJob} from '../../utils/JobDetailHelper/JobDetailHelper';
import {useAppSelector} from '../../store';
import {JOB_DETAIL_SLICE_NAME} from '../../slices/constants/slice-constants';

const HiringProcess = () => {
  const {jobDetail} = useAppSelector(state => {
    return {...state[JOB_DETAIL_SLICE_NAME]};
  });

  const {jobTitle} = jobDetail;

  let steps = [
    {icon: IconHiringProcessApply, title: 'Job application and preview'},
    {icon: IconHiringProcessPrehire, title: 'Attend office hours'},
    {icon: IconHiringProcessOrientation, title: 'Online orientation'},
    {icon: IconHiringProcessFirstDay, title: 'Your first day!'},
  ];

  if (isCustomerServiceJob(jobTitle)) {
    steps = [
      {icon: IconCSHiringProcessApply, title: 'Job application'},
      {icon: IconCSHiringProcessAssessment, title: 'Work assessment'},
      {icon: IconCSHiringProcessOrientation, title: 'Online pre-hire orientation'},
      {icon: IconCSHiringProcessFirstDay, title: 'Your first day!'},
    ];
  }

  return (
    <Col gridGap={StencilSpacingEnum.S400} flex={1} backgroundColor={CommonColors.White}>
      <Text fontWeight="bold" accessibilityRole="header">
        Hiring Process
      </Text>
      <Text>{"Here's what to expect from the moment you hit 'apply', to your first day at work."}</Text>
      {steps.map(step => (
        <Row alignItems="center" gridGap="S300" key={step.title}>
          <step.icon />
          <Text>{step.title}</Text>
        </Row>
      ))}
    </Col>
  );
};

export default HiringProcess;
