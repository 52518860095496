import React, {useCallback, useMemo} from 'react';

import {CommonFilterOptions} from '../../common/filterOptions/constants/filterOption-constants';
import {useAppDispatch, useAppSelector} from '../../store';
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup';
import {setJobType} from '../../slices/request.slice';
import {REQUEST_SLICE_NAME} from '../../slices/constants/slice-constants';
import {FilterTitle} from '../../common/constant';

const JobTypeSelection = () => {
  const dispatch = useAppDispatch();
  const jobType = useAppSelector(state => {
    return state[REQUEST_SLICE_NAME].jobType;
  });

  const onJobTypeChange = useCallback(
    option => {
      // option value null is "Any"
      if (!option.value) {
        dispatch(setJobType([]));
      } else {
        dispatch(setJobType([option]));
      }
    },
    [dispatch],
  );

  const selected = useMemo(() => {
    if (jobType.length) {
      return CommonFilterOptions.workHourOptions.find(option => option.value === jobType[0].value);
    }

    return CommonFilterOptions.workHourOptions[0];
  }, [jobType]);

  return (
    <RadioButtonGroup
      options={CommonFilterOptions.workHourOptions}
      selected={selected}
      onChange={onJobTypeChange}
      title={FilterTitle.HoursPerWeek}
    />
  );
};

export default JobTypeSelection;
