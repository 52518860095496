import {API} from '@amzn/showtime';
import Cookies from 'js-cookie';

import {Stage} from '../common/constant';
import {getStage} from '../utils/helper';

export const getToken = async () => {
  if (getStage() === Stage.DEV) {
    /**
     * For local web development we use API since it automatically looks
     * up the DEV_AUTH_TOKEN
     */
    return API.apiConfig.getAccessToken();
  }
  return Cookies.get('atoz-oauth-token');
};
