import {Checkbox, Flex} from '@amzn/atoz-native';
import React, {useCallback, useEffect, useState} from 'react';

import {OptionType} from '../../common/filterOptions/types';
import {objAreEqual} from '../../common/utils';
import {emitMetric} from '../../utils/metricsHelper';
import {MetricNames} from '../../common/constant';
import {camelize} from '../../utils/commonHelper';

import styles from './styles';

export enum CheckboxGroupLayout {
  VERTICAL = 'vertical',
  HORIZONTAL = 'horizontal',
}

interface CheckboxGroupProp {
  options: OptionType[];
  onChange?: (value: OptionType[]) => void;
  layout?: string;
  selectedValues: OptionType[];
  title: string;
}

const CheckboxGroup = ({options, onChange, layout, selectedValues, title}: CheckboxGroupProp): JSX.Element => {
  const processSelection = (selectedValues: OptionType[]) => {
    const result: Record<string, OptionType> = {};

    for (const selection of selectedValues) {
      result[selection.shownValue] = selection;
    }

    return result;
  };

  const [selectedOptions, setSelectedOptions] = useState<Record<string, OptionType>>(processSelection(selectedValues));

  useEffect(() => {
    const newSelections = processSelection(selectedValues);

    if (selectedValues !== undefined && !objAreEqual(newSelections, selectedOptions)) {
      setSelectedOptions(newSelections);
    }
  }, [selectedOptions, selectedValues]);

  const handleCheckboxChange = useCallback(
    (selection: OptionType, newValue: boolean) => {
      emitMetric(
        `${newValue ? MetricNames.UserActionFilterSelected : MetricNames.UserActionFilterUnSelected}.${camelize(
          title,
          true,
        )}`,
        selection.shownValue,
      );

      setSelectedOptions(prevSelectedValue => {
        const newSelections = {...prevSelectedValue};

        if (newValue && selection.shownValue) {
          newSelections[selection.shownValue] = selection;
        }

        if (!newValue && selection.shownValue) {
          delete newSelections[selection.shownValue];
        }

        onChange!(Object.values(newSelections));

        return newSelections;
      });
    },
    [onChange, title],
  );

  return (
    <Flex
      testID="checkbox-group"
      style={[
        styles.container,
        layout === CheckboxGroupLayout.HORIZONTAL ? styles.horizontalContainer : styles.verticalContainer,
      ]}
      gridGap="S300"
    >
      {options.map(({value, shownValue}: OptionType) => {
        return (
          <Checkbox
            accessibilityLabel={`${shownValue}`}
            labelText={shownValue}
            checked={Boolean(selectedOptions[shownValue])}
            key={shownValue}
            onChange={(newValue: boolean) => handleCheckboxChange({value, shownValue}, newValue)}
          />
        );
      })}
    </Flex>
  );
};

export const defaultProps: Partial<CheckboxGroupProp> = {
  layout: CheckboxGroupLayout.VERTICAL,
  onChange: () => {},
};

CheckboxGroup.defaultProps = defaultProps;

export default CheckboxGroup;
