import React, {ReactElement} from 'react';
import {View} from 'react-native';
import {Text} from '@amzn/stencil-react-native-components/text';
import {StencilFontSizeEnum} from '@amzn/stencil-react-native-components/types';

interface JobCardChipProps {
  chipColor: string;
  text: string;
}
const JobCardChip = (props: JobCardChipProps): ReactElement => {
  return (
    <View
      accessibilityLabel={props.text}
      style={{
        backgroundColor: props.chipColor,
        paddingVertical: 3,
        paddingHorizontal: 8,
      }}
    >
      <Text fontSize={StencilFontSizeEnum.T300}>{props.text}</Text>
    </View>
  );
};

export default JobCardChip;
