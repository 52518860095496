import React, {useCallback} from 'react';
import {Input} from '@amzn/stencil-react-native-components/form';
import {Col} from '@amzn/stencil-react-native-components/layout';
import {IconSearch} from '@amzn/stencil-react-native-components/icons';
import {StencilSpacingEnum} from '@amzn/stencil-react-native-components/types';
import {View} from 'react-native';
import {useDispatch, connect} from 'react-redux';

import {CommonColors} from '../../common/color';
import {setKeyword} from '../../slices/request.slice';
import {Native_Return_Keys} from '../../utils/keyboardUtils';
import {emitMetric} from '../../utils/metricsHelper';
import {LOCATION_SLICE_NAME, REQUEST_SLICE_NAME} from '../../slices/constants/slice-constants';
import {getLocationByAddressAction} from '../../epics/getLocation.epic';
import {LocationState, setAddress} from '../../slices/location.slice';
import {RootState, useAppSelector} from '../../store';
import {LOCATION_MAX_CHAR_LIMIT, MetricNames} from '../../common/constant';
import IconLocationSearch from '../Icons/IconLocationSearch';
import {logging} from '../../logger';

interface SearchPanelProps {
  onSubmit?: () => void;
  location: LocationState;
}

const SearchPanel = ({onSubmit, location}: SearchPanelProps) => {
  const dispatch = useDispatch();
  const address = location.address;
  const keyword = useAppSelector(state => {
    return state[REQUEST_SLICE_NAME].keywords;
  });

  logging.info('Loading Search Panel');
  const handleKeywordSearchInputOnChangeText = useCallback(
    (value: string) => {
      dispatch(setKeyword(value));
    },
    [dispatch],
  );

  const handleOnSubmitEditing = useCallback(() => {
    if (onSubmit) {
      onSubmit();
    }

    emitMetric(MetricNames.UserActionKeywordSearch, keyword);
  }, [onSubmit, keyword]);

  const handleLocationSubmitEditing = useCallback(() => {
    dispatch(getLocationByAddressAction({address}));
    emitMetric(MetricNames.UserActionLocationSearch, address);
  }, [address, dispatch]);

  return (
    <View
      style={{
        paddingTop: 20,
        paddingHorizontal: 16,
        backgroundColor: CommonColors.White,
      }}
    >
      <Col gridGap={StencilSpacingEnum.S200}>
        <Input
          accessible
          accessibilityRole="search"
          accessibilityLabel="Search jobs by title or keyword"
          testID="keywordSearchInput"
          insetElementLeading={{element: IconSearch}}
          placeholder="Search jobs by title or keyword"
          onChangeText={handleKeywordSearchInputOnChangeText}
          returnKeyType={Native_Return_Keys.SEARCH}
          onSubmitEditing={handleOnSubmitEditing}
          disabled={location.fetchingDefaultAddress}
        />
        <Input
          accessible
          accessibilityLabel="Search by Location - Enter city or zip code"
          testID="locationSearchInput"
          insetElementLeading={{element: IconLocationSearch}}
          placeholder="Enter city or zip code"
          onChangeText={value => dispatch(setAddress({address: value.substring(0, LOCATION_MAX_CHAR_LIMIT)}))}
          returnKeyType={Native_Return_Keys.SEARCH}
          onSubmitEditing={handleLocationSubmitEditing}
          value={address}
          disabled={location.fetchingDefaultAddress}
          maxLength={LOCATION_MAX_CHAR_LIMIT}
        />
      </Col>
    </View>
  );
};

const mapStateToProps = (state: RootState) => {
  return {location: state[LOCATION_SLICE_NAME]};
};

export default connect(mapStateToProps)(SearchPanel);
