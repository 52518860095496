import * as React from 'react';
import Svg, {SvgProps, Circle, Path} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={56} height={57} fill="none" {...props}>
    <Circle cx={28} cy={28.749} r={28} fill="#D7EBFB" />
    <Path stroke="#203040" strokeLinecap="round" strokeLinejoin="round" d="M22.009 30.145v-3.444h20.866v5.813" />
    <Path
      fill="#203040"
      d="M25.578 33.815a.705.705 0 1 0 0-1.41.705.705 0 0 0 0 1.41ZM29.009 33.815a.705.705 0 1 0 0-1.41.705.705 0 0 0 0 1.41ZM32.442 33.815a.705.705 0 1 0 0-1.41.705.705 0 0 0 0 1.41ZM35.876 33.815a.705.705 0 1 0 0-1.41.705.705 0 0 0 0 1.41ZM39.31 33.815a.705.705 0 1 0-.001-1.41.705.705 0 0 0 0 1.41ZM25.578 29.208a.704.704 0 1 0 0-1.409.704.704 0 0 0 0 1.41ZM39.309 29.208a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41ZM25.578 37.337a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41ZM29.009 37.337a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41ZM32.442 37.337a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41ZM35.876 37.337a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41ZM39.31 37.337a.705.705 0 1 0-.001-1.409.705.705 0 0 0 0 1.41ZM25.578 40.86a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41ZM29.009 40.86a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41ZM32.442 40.86a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41ZM35.876 40.86a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41ZM39.31 40.86a.705.705 0 1 0 0-1.409.705.705 0 0 0 0 1.41Z"
    />
    <Path
      stroke="#203040"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M42.875 30.146V43.21H22.009V30.146h20.866ZM39.309 28.503v-2.747M25.578 28.503v-2.747"
    />
    <Path
      stroke="#203040"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M19.651 34.563c-5.536 0-10.026-4.49-10.026-10.027 0-5.536 4.49-10.026 10.026-10.026 5.537 0 10.027 4.49 10.027 10.026"
    />
    <Path stroke="#203040" strokeLinecap="round" strokeLinejoin="round" d="M20.329 17.355v7.588h-4.336" />
  </Svg>
);

export default SvgComponent;
