import {Reducer, combineReducers} from 'redux';

import {
  ALERTS_SLICE_NAME,
  JOB_CARDS_SLICE_NAME,
  LOCATION_SLICE_NAME,
  REQUEST_SLICE_NAME,
  JOB_DETAIL_SLICE_NAME,
  JOB_SCHEDULE_SLICE_NAME,
} from './constants/slice-constants';
import locationReducer, {LocationState, initialLocationState} from './location.slice';
import jobCardsReducer, {JobCardsState, initialJobCardsState} from './jobCards.slice';
import requestReducer, {RequestState, initialRequestState} from './request.slice';
import alertsReducer, {AlertsState, initialAlertsState} from './alerts.slice';
import jobDetailReducer, {JobDetailState, initialJobDetailState} from './jobDetail.slice';
import jobSchedulesReducer, {JobSchedulesState, initialJobScheduleState} from './jobSchedules.slice';

export interface RootState {
  [JOB_CARDS_SLICE_NAME]: JobCardsState;
  [LOCATION_SLICE_NAME]: LocationState;
  [REQUEST_SLICE_NAME]: RequestState;
  [ALERTS_SLICE_NAME]: AlertsState;
  [JOB_DETAIL_SLICE_NAME]: JobDetailState;
  [JOB_SCHEDULE_SLICE_NAME]: JobSchedulesState;
}

export const defaultRootState: RootState = {
  [JOB_CARDS_SLICE_NAME]: initialJobCardsState,
  [LOCATION_SLICE_NAME]: initialLocationState,
  [REQUEST_SLICE_NAME]: initialRequestState,
  [ALERTS_SLICE_NAME]: initialAlertsState,
  [JOB_DETAIL_SLICE_NAME]: initialJobDetailState,
  [JOB_SCHEDULE_SLICE_NAME]: initialJobScheduleState,
};

const rootReducer: Record<string, Reducer> = {
  [JOB_CARDS_SLICE_NAME]: jobCardsReducer,
  [LOCATION_SLICE_NAME]: locationReducer,
  [REQUEST_SLICE_NAME]: requestReducer,
  [ALERTS_SLICE_NAME]: alertsReducer,
  [JOB_DETAIL_SLICE_NAME]: jobDetailReducer,
  [JOB_SCHEDULE_SLICE_NAME]: jobSchedulesReducer,
};

const createRootReducer = () => combineReducers(rootReducer);

export default createRootReducer;
