import React, {useCallback} from 'react';

import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import {useAppDispatch, useAppSelector} from '../../store';
import {REQUEST_SLICE_NAME} from '../../slices/constants/slice-constants';
import {setScheduleShift} from '../../slices/request.slice';
import {OptionType, ScheduleShifts} from '../../common/filterOptions/types';
import {initScheduleShifts} from '../../common/filterOptions/constants/filterOption-constants';
import {FilterTitle} from '../../common/constant';

export const TimeShiftSelection = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const scheduleShift = useAppSelector(state => {
    return state[REQUEST_SLICE_NAME].scheduleShift;
  });

  const handleOnScheduleShiftChange = useCallback(
    (options: OptionType[]) => {
      dispatch(setScheduleShift(options as ScheduleShifts));
    },
    [dispatch],
  );

  return (
    <CheckboxGroup
      selectedValues={scheduleShift}
      options={initScheduleShifts}
      onChange={handleOnScheduleShiftChange}
      title={FilterTitle.TimeOfShift}
    />
  );
};

export default React.memo(TimeShiftSelection);
