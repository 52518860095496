import {PayloadAction, createSlice} from '@reduxjs/toolkit';

import {
  USFilterOptions,
  payRateConfigDefault,
  workHoursConfigDefault,
} from '../common/filterOptions/constants/filterOption-constants';
import {
  DistanceOption,
  LanguageRequirementOption,
  LengthOfEmploymentOption,
  OptionType,
  ScheduleShifts,
  SortOption,
  WorkHourOptions,
} from '../common/filterOptions/types';
import {NumberPair, StartDateOptionType} from '../common/types';

import {REQUEST_SLICE_NAME} from './constants/slice-constants';
import {RootState} from './root.slice';

const {distanceOptions, lengthOfEmploymentOptions, languageRequirementOptions, sortOptions} = USFilterOptions;

export interface Filters {
  sort: SortOption;
  languageRequirement: LanguageRequirementOption;
  featured: boolean;
  bonus: boolean;
  virtual: boolean;
  hoursPerWeek: NumberPair;
  payRate: NumberPair;
}

export interface RequestState {
  filters: Filters;
  scheduleShift: ScheduleShifts;
  jobTitle: OptionType[];
  weekShifts: ScheduleShifts;
  keywords: string;
  jobType: WorkHourOptions;
  firstDayOnSite: StartDateOptionType[];
  employmentType: LengthOfEmploymentOption;
  distance: DistanceOption;
  sorter: SortOption;
}

export const selectRequestState = (state: RootState) => state[REQUEST_SLICE_NAME];

export const initialRequestState: RequestState = {
  filters: {
    sort: sortOptions[0],
    languageRequirement: languageRequirementOptions[0],
    featured: false,
    bonus: false,
    virtual: false,
    hoursPerWeek: workHoursConfigDefault.initHoursPerWeek,
    payRate: payRateConfigDefault.initPayRate,
  },
  keywords: '',
  scheduleShift: [],
  jobTitle: [],
  weekShifts: [],
  jobType: [],
  firstDayOnSite: [],
  sorter: sortOptions[1],
  employmentType: lengthOfEmploymentOptions[0],
  distance: distanceOptions.find(option => option.defaultValue)!,
};

const {actions, reducer} = createSlice({
  name: REQUEST_SLICE_NAME,
  initialState: initialRequestState,
  reducers: {
    resetRequestState: () => {
      return initialRequestState;
    },
    setKeyword: (state: RequestState, {payload}) => {
      state.keywords = payload;
    },
    setWeekShifts: (state: RequestState, {payload}: PayloadAction<ScheduleShifts>) => {
      state.weekShifts = payload;
    },
    setScheduleShift: (state: RequestState, {payload}: PayloadAction<ScheduleShifts>) => {
      state.scheduleShift = payload;
    },
    setJobTitle: (state: RequestState, {payload}: PayloadAction<OptionType[]>) => {
      state.jobTitle = payload;
    },
    setJobType: (state: RequestState, {payload}: PayloadAction<WorkHourOptions>) => {
      state.jobType = payload;
    },
    setFirstDayOnSite: (state: RequestState, {payload}: PayloadAction<StartDateOptionType[]>) => {
      state.firstDayOnSite = payload;
    },
    setEmploymentType: (state: RequestState, {payload}: PayloadAction<LengthOfEmploymentOption>) => {
      state.employmentType = payload;
    },
    setDistance: (state: RequestState, {payload}: PayloadAction<DistanceOption>) => {
      state.distance = payload;
    },
    setSorter: (state: RequestState, {payload}: PayloadAction<SortOption>) => {
      state.sorter = payload;
    },
  },
});

export const {
  resetRequestState,
  setKeyword,
  setScheduleShift,
  setJobTitle,
  setWeekShifts,
  setJobType,
  setFirstDayOnSite,
  setEmploymentType,
  setDistance,
  setSorter,
} = actions;

export default reducer;
