import {ApolloClient, InMemoryCache, HttpLink} from '@apollo/client';

import {Stage} from '../common/constant';
import {getStage} from '../utils/helper';

import {getToken} from './getToken';

const stage = getStage();

const xApiKey: {[key in Stage]: string} = {
  dev: 'da2-c7k2dkbnfndd3emfoyrxs66vuq',
  beta: 'da2-c7k2dkbnfndd3emfoyrxs66vuq',
  prod: 'da2-ptbhmdgz5fbvfm2ug6qk5z4uai',
};

export const getApiGatewayHeaders = async () => {
  const token = await getToken();

  return {
    'x-atoz-client-id': 'ATOZ_HVH_CAREERSITE_APPSYNC_SERVICE',
    'x-atoz-tenant-x-api-key': xApiKey[stage],
    Authorization: `Bearer ${token}`,
  };
};

const apiGatewayUrl: {[key in Stage]: string} = {
  dev: 'https://atoz-api.integ.amazon.com',
  beta: 'https://atoz-api.integ.amazon.com',
  prod: 'https://atoz-api.amazon.work',
};

const getCredentials = (): string => (stage === Stage.DEV ? 'same-origin' : 'include');

export const createHttpLink = async () => {
  const headers = await getApiGatewayHeaders();

  return new HttpLink({
    uri: `${apiGatewayUrl[stage]}/graphql`,
    headers,
    credentials: getCredentials(),
  });
};

export const getApolloClient = async () => {
  const httpLink = await createHttpLink();

  return new ApolloClient({
    link: httpLink,
    cache: new InMemoryCache(),
    connectToDevTools: true,
  });
};
