import {
  CurrencyCodeMap,
  JobTypeRawDataEnum,
  NotApplicable,
  VirtualLocationRawDataEnum,
  EmploymentTypeRawDataEnum,
} from '../../common/constant';
import {DataSource, JobCard, jobContainerJobMeta, PayRateType} from '../../common/types';
import {getCurrencyFromCurrencyCode, isNonZeroNumber} from '../commonHelper';
export const isDragonStoneJobOrNot = (dataSource: string | null | undefined) => {
  if (!dataSource) return false;

  return dataSource.toUpperCase() === DataSource.DragonStone;
};
export const isSalesForceJobOrNot = (dataSource: string | null | undefined) => {
  if (!dataSource) return false;

  return dataSource.toUpperCase() === DataSource.SalesForce;
};

export const getBannerData = (jobCard: Partial<JobCard>) => {
  const {currencyCode, surgePay, featuredJob, bannerText, bonusJob, bonusPay, bonusPayL10N, dataSource} = jobCard;

  const dgsBonusPay =
    bonusPay && currencyCode && CurrencyCodeMap[currencyCode]
      ? `Bonus ${CurrencyCodeMap[currencyCode]}${bonusPay}`
      : 'Bonus';

  if (!isDragonStoneJobOrNot(dataSource)) {
    if (bonusPayL10N) return `Bonus ${bonusPayL10N}`;
    if (bannerText) return bannerText;

    return featuredJob ? 'Featured' : null;
  }

  if (bonusJob) return dgsBonusPay;
  if (surgePay) return 'Premium Pay';
  if (bannerText) return bannerText;
  if (featuredJob) return 'Featured';

  return null;
};

export const getPayRateTextWithPrefix = (
  totalPayRateMin: number,
  totalPayRateMax: number,
  currencyCode: string | null,
) => {
  const getPayRateText = () => {
    const currency = getCurrencyFromCurrencyCode(currencyCode);
    let pay = totalPayRateMin;

    if (countryPayRate === PayRateType.hourMax) {
      pay = totalPayRateMax;
    }

    const trimmedPay = Number.isInteger(pay) ? pay : pay?.toFixed(2);

    return trimmedPay && currency ? `${currency}${trimmedPay}` : null;
  };

  const countryPayRate: PayRateType = PayRateType.hourMax;
  const payRateText = getPayRateText();
  let payRateTextWithPrefix;

  if (!payRateText) {
    payRateTextWithPrefix = NotApplicable;
  } else {
    payRateTextWithPrefix = `Up to ${payRateText}/hr`;
  }

  return payRateTextWithPrefix;
};

export const mapJobTypeDisplayValue = (jobTypeInput: string) => {
  switch (jobTypeInput.toLowerCase()) {
    case JobTypeRawDataEnum.Part:
    case JobTypeRawDataEnum.PartTimeDash:
    case JobTypeRawDataEnum.PartTimeUnderScore:
      return 'Part Time';
    case JobTypeRawDataEnum.Full:
    case JobTypeRawDataEnum.FullTimeDash:
    case JobTypeRawDataEnum.FullTimeUnderScore:
    case JobTypeRawDataEnum.ReduceTimeUnderScore:
      return 'Full Time';
    case JobTypeRawDataEnum.Flex:
    case JobTypeRawDataEnum.FlexTimeUnderScore:
      return 'Flexible Shifts';
    default:
      return jobTypeInput;
  }
};

export const isRemoteJob = (
  dataSource: string | null,
  virtualLocation?: string | null,
  jobContainerJobMetaL1?: string[] | null,
) => {
  return isSalesForceJobOrNot(dataSource)
    ? virtualLocation === VirtualLocationRawDataEnum.Yes ||
        Boolean(jobContainerJobMetaL1?.includes(jobContainerJobMeta.VIRTUAL))
    : Boolean(jobContainerJobMetaL1?.includes(jobContainerJobMeta.VIRTUAL));
};

export const renderJobCardDistanceContent = (
  jobCard: Partial<JobCard>,
  unit: string,
): {locationInfo: string; distanceText: string; roundedDistanceShown: number | string} => {
  const {city, state, distance, locationName, dataSource} = jobCard;

  const isDistanceNonZeroNumber = distance && isNonZeroNumber(distance);
  const roundedDistance = isDistanceNonZeroNumber ? Math.round(distance * 10) / 10 : NotApplicable;
  const distanceText = isDistanceNonZeroNumber ? `${roundedDistance} ${unit}` : NotApplicable;

  const locationInfo = isDragonStoneJobOrNot(dataSource)
    ? city
      ? `${city}${state ? `, ${state}` : ''}`
      : locationName || ''
    : city
    ? `${city}${state ? `, ${state}` : ''}`
    : state || '';

  return {locationInfo, distanceText, roundedDistanceShown: roundedDistance};
};

const dedupAndTransform = (input: string | null, transformer: Function, fromSeparator = ';', toSeparator = ', ') => {
  if (!input) return NotApplicable;

  const transformValue = input.split(fromSeparator).map(val => transformer(val));
  const transformValueUnique = new Set(transformValue);

  return [...transformValueUnique].join(toSeparator);
};

export const getJobTypeText = (jobType: string | null) => {
  return dedupAndTransform(jobType, mapJobTypeDisplayValue);
};

export const getEmploymentTypeText = (employmentType: string | null) => {
  return dedupAndTransform(employmentType, (item: string) => {
    return item.toLowerCase() === EmploymentTypeRawDataEnum.Temporary.toLowerCase()
      ? EmploymentTypeRawDataEnum.Seasonal
      : item;
  });
};
