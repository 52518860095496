import {Row, Text} from '@amzn/atoz-native';
import React, {useCallback, useMemo} from 'react';
import {StyleSheet, TouchableWithoutFeedback, View} from 'react-native';
import {useActions} from '@amzn/showtime';
import {createSelector} from '@reduxjs/toolkit';

import {CommonColors} from '../../common/color';
import {FilterScreenPlacementURI, getUri} from '../../utils/uriUtils/uriUtils';
import {useAppSelector} from '../../store';
import {selectJobCardState} from '../../slices/jobCards.slice';
import IconFilter from '../Icons/IconFilter';
import {useJobRequest} from '../../common/hooks/useJobRequest';
import {MaxDistance} from '../../common/constant';

const selectFilterBarState = createSelector([selectJobCardState], jobCardState => {
  return {
    jobCards: jobCardState.jobCards,
  };
});

export const FilterBar = (): JSX.Element => {
  const {jobCards} = useAppSelector(selectFilterBarState);
  const {pushUri} = useActions();
  const {containFilters, dateFilters, equalFilters, geoQueryClause, sorters, rangeFilters} = useJobRequest();

  const filterCount = useMemo(() => {
    let totalFilters = 0;

    for (const filter of [...containFilters]) {
      totalFilters += filter.val.length;
    }

    totalFilters += dateFilters.length;
    totalFilters += equalFilters.length;
    totalFilters += sorters.length;
    totalFilters += rangeFilters.length;

    if (geoQueryClause.distance && geoQueryClause.distance !== MaxDistance) {
      totalFilters += 1;
    }

    return totalFilters;
  }, [
    containFilters,
    dateFilters.length,
    equalFilters.length,
    geoQueryClause.distance,
    rangeFilters.length,
    sorters.length,
  ]);

  const getCardCount = useMemo(() => {
    return jobCards?.length || 0;
  }, [jobCards]);

  const handleOnButtonPress = useCallback(() => {
    pushUri(getUri(FilterScreenPlacementURI));
  }, [pushUri]);

  return (
    <View style={styles.container}>
      <Row alignItems="center" justifyContent="space-between">
        <Text style={styles.text}>{`${getCardCount} results found`}</Text>
        <TouchableWithoutFeedback
          accessibilityRole="button"
          accessible={true}
          accessibilityLabel={`Filters (${filterCount}) Button`}
          testID="filterButton"
          onPress={handleOnButtonPress}
          style={styles.clickableElement}
        >
          <Row alignItems="center">
            <IconFilter color={styles.icon.color} />
            <Text style={styles.filterText}>{`Filters (${filterCount})`}</Text>
          </Row>
        </TouchableWithoutFeedback>
      </Row>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: CommonColors.White,
    paddingBottom: 18,
    paddingTop: 18,
    borderBottomWidth: 1, // Add a bottom border
    borderBottomColor: CommonColors.Neutral20,
  },
  clickableElement: {
    flexDirection: 'row', // Align icon and text in a row
    alignItems: 'center', // Align vertically in the middle
  },
  text: {
    color: CommonColors.Neutral70,
  },
  icon: {
    color: CommonColors.White,
  },
  filterText: {
    marginLeft: 5,
    color: CommonColors.Blue70,
  },
});

export default FilterBar;
