import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  footerContainer: {
    width: '100%',
  },
  buttonContainer: {
    width: '50%',
  },
});

export default styles;
