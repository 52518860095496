import * as React from 'react';
import Svg, {SvgProps, G, Path, Defs, ClipPath} from 'react-native-svg';
import {withIconProps} from '@amzn/stencil-react-native-components/components/icons/withIconProps';

function Icon(props: SvgProps) {
  return (
    <Svg width={20} height={20} {...props}>
      <G clipPath="url(#atoz-filter_svg__clip0_206_11003)" stroke={props.color || '#1768C9'} {...props}>
        <Path
          d="M13.75 4.306H1.25v1.25h12.5v-1.25zM4.514 9.722H1.25v1.25h3.264v-1.25zM18.75 9.722H9.375v1.25h9.375v-1.25zM10.278 16.042H1.25v1.25h9.028v-1.25zM18.75 16.042H15v1.25h3.75v-1.25z"
          fill="#1768C9"
          strokeWidth={0.92}
        />
        <Path
          d="M16.493 7.014a2.257 2.257 0 100-4.514 2.257 2.257 0 000 4.514zM7.118 12.43a2.257 2.257 0 100-4.513 2.257 2.257 0 000 4.514zM12.535 18.75a2.257 2.257 0 100-4.514 2.257 2.257 0 000 4.514z"
          strokeWidth={1.839}
        />
      </G>
      <Defs>
        <ClipPath id="atoz-filter_svg__clip0_206_11003">
          <Path d="M0 0h20v20H0z" />
        </ClipPath>
      </Defs>
    </Svg>
  );
}

const SvgAtozFilter = withIconProps(Icon);

export default SvgAtozFilter;
