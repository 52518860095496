import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {Button, ButtonVariant, Col, Expander, Hr, Row} from '@amzn/atoz-native';
import {Footer} from '@amzn/stencil-react-native-components/footer';
import {FlatList, View} from 'react-native';
import {useActions} from '@amzn/showtime';
import {createSelector} from '@reduxjs/toolkit';

import ExpanderSection from '../../components/ExpanderSection/ExpanderSection';
import {getUri, JobSearchPlacementURI} from '../../utils/uriUtils/uriUtils';
import {CommonColors} from '../../common/color';
import {useAppDispatch, useAppSelector} from '../../store';
import {resetRequestState} from '../../slices/request.slice';
import {jobSearchRequestGenerator} from '../../utils/requestService';
import {getJobCardsByLocationAction} from '../../epics/getJobs.epic';
import {selectJobCardState} from '../../slices/jobCards.slice';
import {useJobRequest} from '../../common/hooks/useJobRequest';
import {JOB_CARDS_REQUEST_LIMIT, MetricNames, FilterTitle} from '../../common/constant';
import {emitMetric, wrapSubSectionCallWithTrace} from '../../utils/metricsHelper';

import TimeShiftSelection from './TimeShiftSelection';
import DepartmentSelection from './DepartmentSelection';
import JobTypeSelection from './JobTypeSelection';
import TimeWeekSelection from './TimeWeekSelection';
import FirstDateOnSiteSelection from './FirstDateOnSiteSelection';
import EmploymentTypeSelection from './EmploymentTypeSelection';
import DistanceSelection from './DistanceSelection';
import SorterSelection from './SorterSelection';
import styles from './styles';

const selectFilterState = createSelector([selectJobCardState], jobCardState => {
  return {
    jobCards: jobCardState.jobCards,
  };
});

export const FilterScreen = () => {
  const isInitialMount = useRef(true);
  const {pushUri} = useActions();
  const dispatch = useAppDispatch();
  const {jobCards} = useAppSelector(selectFilterState);
  const jobSearchRequest = useJobRequest();

  const jobSearchRequestResult = useMemo(
    () => jobSearchRequestGenerator(jobSearchRequest),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      jobSearchRequest.containFilters,
      jobSearchRequest.dateFilters,
      jobSearchRequest.equalFilters,
      jobSearchRequest.geoQueryClause,
      jobSearchRequest.sorters,
      jobSearchRequest.rangeFilters,
    ],
  );

  useEffect(() => {
    wrapSubSectionCallWithTrace(MetricNames.SubSectionFilterScreenLoad, () => {
      if (isInitialMount.current) {
        isInitialMount.current = false;
      } else {
        dispatch(getJobCardsByLocationAction({request: jobSearchRequestResult}));
      }
    });
  }, [dispatch, jobSearchRequestResult]);

  const jobCountText = useMemo(() => {
    let count = jobCards ? ` ${jobCards.length}` : '';

    if (jobCards && jobCards.length >= JOB_CARDS_REQUEST_LIMIT) {
      count = `${count}+`;
    }

    return count;
  }, [jobCards]);

  const handleOnApply = useCallback(() => {
    emitMetric(MetricNames.UserActionShowFilterResultClick, jobCountText.trim());
    pushUri(getUri(JobSearchPlacementURI));
  }, [jobCountText, pushUri]);

  const handleOnClear = useCallback(() => {
    emitMetric(MetricNames.UserActionResetAllFilterClick);
    dispatch(resetRequestState());
  }, [dispatch]);

  const data = useMemo(
    () => [
      {
        title: FilterTitle.SortBy,
        component: <SorterSelection />,
      },
      {
        title: FilterTitle.MaximumCommuteDistance,
        component: <DistanceSelection />,
      },
      {
        title: FilterTitle.HoursPerWeek,
        component: <JobTypeSelection />,
      },
      {
        title: FilterTitle.TimeOfShift,
        component: <TimeShiftSelection />,
      },
      {
        title: FilterTitle.TimeOfWeek,
        component: <TimeWeekSelection />,
      },
      {
        title: FilterTitle.LengthOfEmployment,
        component: <EmploymentTypeSelection />,
      },
      {
        title: FilterTitle.StartDate,
        component: <FirstDateOnSiteSelection />,
      },
      {
        title: FilterTitle.Department,
        component: <DepartmentSelection />,
      },
    ],
    [],
  );

  const renderItem = useCallback(
    ({item: {title, component}}) => (
      <Expander key={title} shouldExpandOnMount titleText={title}>
        <ExpanderSection>{component}</ExpanderSection>
      </Expander>
    ),
    [],
  );

  const renderSeparator = useCallback(() => <Hr />, []);

  return (
    <>
      <Col flex={1} backgroundColor={CommonColors.White}>
        <FlatList
          removeClippedSubviews={false}
          ItemSeparatorComponent={renderSeparator}
          data={data}
          renderItem={renderItem}
          initialNumToRender={data.length}
        />
      </Col>
      <Footer>
        <Row style={styles.footerContainer} gridGap={20} justifyContent="space-around">
          <View style={styles.buttonContainer}>
            <Button onPress={handleOnClear}>Reset all</Button>
          </View>
          <View style={styles.buttonContainer}>
            <Button variant={ButtonVariant.Primary} onPress={handleOnApply}>
              {`Show${jobCountText} results`}
            </Button>
          </View>
        </Row>
      </Footer>
    </>
  );
};

export default React.memo(FilterScreen);
