import React, {useCallback} from 'react';

import {CommonFilterOptions} from '../../common/filterOptions/constants/filterOption-constants';
import {FilterTitle} from '../../common/constant';
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup';
import {useAppDispatch, useAppSelector} from '../../store';
import {REQUEST_SLICE_NAME} from '../../slices/constants/slice-constants';
import {setDistance} from '../../slices/request.slice';

const DistanceSelection = () => {
  const dispatch = useAppDispatch();
  const distance = useAppSelector(state => {
    return state[REQUEST_SLICE_NAME].distance;
  });

  const handleOnDistanceChange = useCallback(
    option => {
      dispatch(setDistance(option));
    },
    [dispatch],
  );

  return (
    <RadioButtonGroup
      selected={distance}
      options={CommonFilterOptions.distanceOptions}
      onChange={handleOnDistanceChange}
      title={FilterTitle.MaximumCommuteDistance}
    />
  );
};

export default DistanceSelection;
