import {concat, from, Observable, of} from 'rxjs';
import {catchError, filter, switchMap, withLatestFrom} from 'rxjs/operators';
import {ApolloQueryResult} from '@apollo/client';
import {createAction} from '@reduxjs/toolkit';
import {StateObservable} from 'redux-observable';

import {searchScheduleCards} from '../api/appSyncAPI/appSyncApi';
import {SearchScheduleResponseGQL} from '../api/appSyncAPI/types';
import {fetchJobSchedules, onReceiveJobSchedules} from '../slices/jobSchedules.slice';
import {RootState} from '../slices/root.slice';
import {createJobRequestInEpic} from '../utils/requestService';
import {ScheduleAggregation} from '../common/types';
import {addAlert} from '../slices/alerts.slice';

export enum JobSchedulesActionTypes {
  GET_JOB_SCHEDULES = 'jobSchedules/getJobSchedules',
}

export interface getJobSchedulesPayload {
  jobId: string;
}

export const getJobSchedulesAction = createAction<getJobSchedulesPayload>(JobSchedulesActionTypes.GET_JOB_SCHEDULES);

export const getJobSchedulesEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(getJobSchedulesAction.match),
    withLatestFrom(state$),
    switchMap(([actions, state]: [any, RootState]): Observable<any> => {
      return concat(
        of(fetchJobSchedules()),
        from(
          searchScheduleCards({...createJobRequestInEpic(state), jobId: actions.payload.jobId, pageSize: 1000}),
        ).pipe(
          switchMap(async (response: ApolloQueryResult<SearchScheduleResponseGQL>) => {
            if (!response?.data?.searchScheduleCards?.scheduleCards) {
              throw new Error('Unable to retrieve schedules for this job');
            }

            const scheduleCards = response.data.searchScheduleCards.scheduleCards;
            const scheduleAggregation: ScheduleAggregation = {};

            const schedulePayRates = new Set<number>();
            const scheduleTypes = new Set<string | null>();
            const scheduleEmploymentType = new Set<string | null>();
            const scheduleCurrencyCode = new Set<string | null>();
            const scheduleSurgePay = new Set<number>();
            const scheduleCities = new Set<string | null>();
            const scheduleStates = new Set<string | null>();
            const schedulePostalCodes = new Set<string | null>();
            const scheduleAddresses = new Set<string | null>();
            const scheduleMonthlyBasePay = new Set<number>();
            const scheduleBasePay = new Set<number>();

            // Aggregate schedule information
            scheduleCards.forEach(scheduleCard => {
              const {totalPayRate, surgePay} = scheduleCard;
              const payRateWithSurgePay = totalPayRate && surgePay ? totalPayRate + surgePay : totalPayRate;

              payRateWithSurgePay !== null && schedulePayRates.add(payRateWithSurgePay);
              scheduleCard.currencyCode && scheduleCurrencyCode.add(scheduleCard.currencyCode);
              scheduleCard.scheduleType && scheduleTypes.add(scheduleCard.scheduleType);
              scheduleCard.employmentType && scheduleEmploymentType.add(scheduleCard.employmentType);

              scheduleCard.surgePay && scheduleSurgePay.add(scheduleCard.surgePay);
              scheduleCard.city && scheduleCities.add(scheduleCard.city);
              scheduleCard.state && scheduleStates.add(scheduleCard.state);
              scheduleCard.postalCode && schedulePostalCodes.add(scheduleCard.postalCode);
              scheduleCard.address && scheduleAddresses.add(scheduleCard.address);
              scheduleCard.monthlyBasePay && scheduleMonthlyBasePay.add(scheduleCard.monthlyBasePay);
              scheduleCard.basePay && scheduleBasePay.add(scheduleCard.basePay);

              if (
                scheduleCard.signOnBonus &&
                (!scheduleAggregation.maxBonus || scheduleCard.signOnBonus > scheduleAggregation.maxBonus)
              ) {
                scheduleAggregation.maxBonus = scheduleCard.signOnBonus;
                if (scheduleCard.signOnBonusL10N) scheduleAggregation.maxBonusL10n = scheduleCard.signOnBonusL10N;
              }
            });

            scheduleAggregation.scheduleCount = scheduleCards.length;
            scheduleAggregation.employmentTypes = Array.from(scheduleEmploymentType).join(';');
            scheduleAggregation.jobTypes = Array.from(scheduleTypes).join(';');

            if (scheduleCurrencyCode.size > 0) scheduleAggregation.currencyCode = Array.from(scheduleCurrencyCode)[0];

            if (schedulePayRates.size > 0) {
              const payRates: number[] = Array.from(schedulePayRates);

              scheduleAggregation.maxPayRate = Math.max(...payRates);
              scheduleAggregation.minPayRate = Math.min(...payRates);
            }

            if (scheduleMonthlyBasePay.size > 0) {
              const monthlyPayRates: number[] = Array.from(scheduleMonthlyBasePay);

              scheduleAggregation.monthlyBasePay = Math.max(...monthlyPayRates);
            }

            if (scheduleBasePay.size > 0) {
              const basePayRates: number[] = Array.from(scheduleBasePay);

              scheduleAggregation.basePay = Math.min(...basePayRates);
            }

            if (scheduleSurgePay.size > 0) {
              const surgePay: number[] = Array.from(scheduleSurgePay);

              scheduleAggregation.maxSurgePay = Math.max(...surgePay);
            }

            if (scheduleCities.size === 1) scheduleAggregation.city = Array.from(scheduleCities)[0];
            if (scheduleStates.size === 1) scheduleAggregation.state = Array.from(scheduleStates)[0];
            if (schedulePostalCodes.size === 1) scheduleAggregation.postalCode = Array.from(schedulePostalCodes)[0];

            if (scheduleAddresses.size === 1) scheduleAggregation.address = Array.from(scheduleAddresses)[0];

            return onReceiveJobSchedules({scheduleCards, scheduleAggregation});
          }),
          catchError(() => {
            return [addAlert({alertMessage: 'Unable to retrieve schedules for this job'})];
          }),
        ),
      );
    }),
  );
