import {DateRange} from '../api/appSyncAPI/types';

import {
  FilterKeys,
  initScheduleShifts,
  initTimeOfWeek,
  StartDateOptionValue,
  WorkHourOptionValues,
} from './filterOptions/constants/filterOption-constants';
import {ScheduleShift} from './filterOptions/types';

/**
 * Compares two objects and determines if they are shallow equal
 *
 * @param obj1 Object 1 to compare
 * @param obj2 Object 2 to compare
 * @returns boolean
 */
export const objAreEqual = (obj1: any, obj2: any): boolean => {
  const keys1 = Object.keys(obj1);
  const keys2 = Object.keys(obj2);

  if (keys1.length !== keys2.length) {
    return false;
  }

  for (const key of keys1) {
    if (obj1[key] !== obj2[key]) {
      return false;
    }
  }

  return true;
};

export const getUrlQueryParam = (url: string, param: string): string | null => {
  const uri = new URL(url);

  return new URLSearchParams(uri.search).get(param);
};

export const isSafeJsonParsable = (str: any) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
};

/**
 * Returns a date range based on the given Start Date Option Value
 *
 * Example
 * Input: StartDateOptionValue.One_TO_TWO_WEEKS)
 * Return: {
 *   startDate: '2021-07-08',
 *   endDate: '2021-07-15',
 * }
 * @param selection StartDateOptionValue
 * @returns date range in YYYY-MM-DD format
 */
export const getStartDateRange = (selection: StartDateOptionValue | null): DateRange => {
  const currentDate = new Date();

  if (!selection) {
    // date.toISOString will return '2013-03-10T02:00:00Z' format. We only need '2013-03-10' part.
    return {
      startDate: currentDate.toISOString().split('T')[0],
    };
  }

  const startDate = new Date();
  const endDate = new Date();

  switch (selection) {
    case StartDateOptionValue.One_TO_TWO_WEEKS:
      startDate.setDate(currentDate.getDate() + 7);
      endDate.setDate(currentDate.getDate() + 14);

      return {
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
      };
    case StartDateOptionValue.TWO_TO_THREE_WEEKS:
      startDate.setDate(currentDate.getDate() + 14);
      endDate.setDate(currentDate.getDate() + 21);

      return {
        startDate: startDate.toISOString().split('T')[0],
        endDate: endDate.toISOString().split('T')[0],
      };
    case StartDateOptionValue.MORE_THAN_THREE_WEEK:
      startDate.setDate(currentDate.getDate() + 21);

      return {
        startDate: startDate.toISOString().split('T')[0],
      };
    default:
      return {
        startDate: currentDate.toISOString().split('T')[0],
      };
  }
};

export const getHoursPerWeekFromJobType = (jobType: string | null): number[] => {
  return jobType === WorkHourOptionValues.FULL_TIME
    ? [30, 80]
    : jobType === WorkHourOptionValues.PART_TIME
    ? [0, 30]
    : [0, 80];
};

export const parseShiftsFromURLParams = (filterString: string | null) => {
  let scheduleShifts = null;
  let weekShifts = null;

  if (!filterString || !isSafeJsonParsable(filterString)) return {scheduleShifts, weekShifts};

  const filters = JSON.parse(filterString);
  const scheduleShift = filters?.containFilters?.find((obj: {key: string}) => obj.key === FilterKeys.SCHEDULE_SHIFTS);
  const shifts = scheduleShift?.val;

  if (!shifts || !Array.isArray(shifts)) return {scheduleShifts, weekShifts};

  scheduleShifts = initScheduleShifts.filter((shift: ScheduleShift) => shifts.includes(shift.value));
  weekShifts = initTimeOfWeek.filter((shift: ScheduleShift) => shifts.includes(shift.value));

  return {scheduleShifts, weekShifts};
};
