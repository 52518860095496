import {combineEpics} from 'redux-observable';

import {queryGeoInfoByAddressEpic, getDefaultAddressEpic} from './getLocation.epic';
import {getJobCardsByLocationEpic} from './getJobs.epic';
import {getJobDetailEpic} from './getJobDetail.epic';
import {getJobSchedulesEpic} from './getJobSchedules.epic';

const rootEpic = combineEpics(
  queryGeoInfoByAddressEpic,
  getJobCardsByLocationEpic,
  getDefaultAddressEpic,
  getJobDetailEpic,
  getJobSchedulesEpic,
);

export default rootEpic;
