import {JobRoleDisplayNameMapType} from '../common/types';
import {EnUsRoleProperty} from '../common/filterOptions/constants/filterOption-constants';
import {CurrencyCodeMap} from '../common/constant';

export const mapJobTitleDisplayValue = (jobTitle: string | null) => {
  if (!jobTitle) return '';

  const JobRoleDisplayMap: JobRoleDisplayNameMapType = {
    [EnUsRoleProperty.FULFILMENT_CENTER_ASSOCIATE]: {
      translationKey: 'JobRole-fulfillment-center-warehouse-associate',
      displayValue: 'Fulfillment Center Warehouse Associate',
    },
    [EnUsRoleProperty.SORTATION_CENTER_ASSOCIATE]: {
      translationKey: 'JobRole-sortation-center-warehouse-associate',
      displayValue: 'Sortation Center Warehouse Associate',
    },
    [EnUsRoleProperty.DELIVERY_STATION_ASSOCIATE]: {
      translationKey: 'JobRole-delivery-station-warehouse-associate',
      displayValue: 'Delivery Station Warehouse Associate',
    },
    [EnUsRoleProperty.DISTRIBUTION_CENTER_ASSOCIATE]: {
      translationKey: 'JobRole-distribution-center-associate',
      displayValue: 'Distribution Center Associate',
    },
    [EnUsRoleProperty.GROCERY_WAREHOUSE_ASSOCIATE]: {
      translationKey: 'JobRole-grocery-warehouse-associate',
      displayValue: 'Grocery Warehouse Associate',
    },
    [EnUsRoleProperty.CENTRAL_OPERATIONS_ASSOCIATE]: {
      translationKey: 'JobRole-central-operations-associate',
      displayValue: 'Central Operations Associate',
    },
    [EnUsRoleProperty.AIR_ASSOCIATE]: {translationKey: 'JobRole-air-associate', displayValue: 'Air Associate'},
    [EnUsRoleProperty.LOCKER_CUSTOMER_ASSOCIATE]: {
      translationKey: 'JobRole-locker-customer-service-associate',
      displayValue: 'Locker+ Customer Service Associate',
    },
    [EnUsRoleProperty.HOURLY_TEAM_MEMBER]: {
      translationKey: 'JobRole-hourly-team-member',
      displayValue: 'Hourly Team Member',
    },
    [EnUsRoleProperty.WAREHOUSE_TEAM_MEMBER]: {
      translationKey: 'JobRole-warehouse-team-member',
      displayValue: 'Warehouse Team Member',
    },
    [EnUsRoleProperty.XL_WAREHOUSE_ASSOCIATE]: {
      translationKey: 'JobRole-xl-warehouse-operative',
      displayValue: 'XL Warehouse Associate',
    },
    [EnUsRoleProperty.CUSTOMER_SERVICE_ASSOCIATE]: {
      translationKey: 'JobRole-customer-service-associate',
      displayValue: 'Customer Service Associate',
    },
    [EnUsRoleProperty.REMOTE_CUSTOMER_SERVICE_ASSOCIATE]: {
      translationKey: 'JobRole-remote-customer-service-associate',
      displayValue: 'Remote Customer Service Associate',
    },
    [EnUsRoleProperty.DELIVERY_STATION_CUSTOMER_SERVICE_ASSOCIATE]: {
      translationKey: 'JobRole-delivery-station-customer-service-associate',
      displayValue: 'Delivery Station Customer Service Associate',
    },
  };

  return JobRoleDisplayMap[jobTitle]?.displayValue || jobTitle;
};

export const isNonZeroNumber = (input: any): boolean => {
  return typeof input === 'number' && isFinite(input) && input !== 0;
};

const firstCharRegex = /(?:^\w|[A-Z]|\b\w)/g;
const whitespaceRegex = /\s+/g;

export const camelize = (str: string, firstLetterCapitalize = false): string => {
  return str
    .replace(firstCharRegex, (word, index) => {
      if (firstLetterCapitalize) return word.toUpperCase();

      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(whitespaceRegex, '');
};

export const getCurrencyFromCurrencyCode = (currencyCode?: string | null): string | null => {
  return !!currencyCode && !!CurrencyCodeMap[currencyCode] ? CurrencyCodeMap[currencyCode] : null;
};
