import {FullScreenSheetPlacement, PlacementType, withErrorBoundary} from '@amzn/showtime';

import {getUriRegexString, ScheduleSelectionScreenPlacementURI} from '../../utils/uriUtils/uriUtils';
import withProviders from '../../placements/Wrapper';
import {emitMetric} from '../../utils/metricsHelper';
import {MetricNames} from '../../common/constant';

import ScheduleSelectionScreen from './ScheduleSelectionScreen';

export const id = ScheduleSelectionScreenPlacementURI.id;
const uri = getUriRegexString(ScheduleSelectionScreenPlacementURI);

const placement: FullScreenSheetPlacement = {
  component: withProviders(withErrorBoundary(ScheduleSelectionScreen)),
  id,
  uri,
  rnwPath: uri,
  title: 'Select a shift and apply',
  type: PlacementType.FullScreenSheet,
  onCloseCompletion: () => {
    emitMetric(MetricNames.UserActionScheduleSelectionScreenClose);
  },
};

export default placement;
