import React, {useCallback, useMemo} from 'react';

import {startDateOptions} from '../../common/filterOptions/constants/filterOption-constants';
import {useAppDispatch, useAppSelector} from '../../store';
import RadioButtonGroup from '../../components/RadioButtonGroup/RadioButtonGroup';
import {setFirstDayOnSite} from '../../slices/request.slice';
import {REQUEST_SLICE_NAME} from '../../slices/constants/slice-constants';
import {FilterTitle} from '../../common/constant';

const FirstDateOnSiteSelection = () => {
  const dispatch = useAppDispatch();
  const firstDayOnSite = useAppSelector(state => {
    return state[REQUEST_SLICE_NAME].firstDayOnSite;
  });

  const handleOnStartDateChange = useCallback(
    option => {
      if (option.value) {
        dispatch(setFirstDayOnSite([option]));
      } else {
        dispatch(setFirstDayOnSite([]));
      }
    },
    [dispatch],
  );

  const selected = useMemo(() => {
    if (firstDayOnSite.length) {
      return startDateOptions.find(option => option.value === firstDayOnSite[0].value);
    }

    return startDateOptions[0];
  }, [firstDayOnSite]);

  return (
    <RadioButtonGroup
      options={startDateOptions}
      selected={selected}
      onChange={handleOnStartDateChange}
      title={FilterTitle.StartDate}
    />
  );
};

export default FirstDateOnSiteSelection;
