import {createSlice} from '@reduxjs/toolkit';
import type {PayloadAction} from '@reduxjs/toolkit';

import {JobCards, JobCardsWithNextToken} from '../common/types';

import {JOB_CARDS_SLICE_NAME} from './constants/slice-constants';
import {RootState} from './root.slice';

export interface JobCardsState {
  jobCards?: JobCards;
  fetchingJobCards?: boolean;
  fetchingMoreJobCards?: boolean;
  nextToken?: string | null;
  prevToken?: string | null;
}

export const initialJobCardsState: JobCardsState = {
  jobCards: undefined,
  fetchingJobCards: false,
  fetchingMoreJobCards: false,
  nextToken: undefined,
};

export const selectJobCardState = (state: RootState) => state[JOB_CARDS_SLICE_NAME];

const {actions, reducer} = createSlice({
  name: JOB_CARDS_SLICE_NAME,
  initialState: initialJobCardsState,
  reducers: {
    resetJobCardsState: () => {
      return initialJobCardsState;
    },
    fetchJobCards: state => {
      state.fetchingJobCards = true;
    },
    fetchMoreJobCards: state => {
      state.fetchingMoreJobCards = true;
    },
    onReceiveJobCardsByLocation: (
      state: JobCardsState,
      {payload: {jobCards, nextToken}}: PayloadAction<JobCardsWithNextToken>,
    ) => {
      state.jobCards = jobCards;
      state.nextToken = nextToken;
      state.fetchingJobCards = false;
    },
    onReceiveJobCardsByNextToken: (
      state: JobCardsState,
      {payload: {jobCards, nextToken}}: PayloadAction<JobCardsWithNextToken>,
    ) => {
      state.jobCards = state.jobCards ? [...state.jobCards, ...jobCards] : jobCards;
      state.nextToken = nextToken;
      state.fetchingMoreJobCards = false;
    },
  },
});

export const {
  onReceiveJobCardsByNextToken,
  resetJobCardsState,
  fetchJobCards,
  onReceiveJobCardsByLocation,
  fetchMoreJobCards,
} = actions;

export default reducer;
