import {Placement} from '@amzn/showtime';

import {hasHVHCareersFeature, hasHVHCareersJobDetail, hasHVHCareersLoadMore, hasSalariedAlumniJobs} from '../features';
import {JOB_CARDS_LOAD_MORE_REQUEST_LIMIT, setJobCardLimit} from '../common/constant';

import FilterScreen from './FilterScreen';
import JobSearchSubsection from './JobSearchSubsection';
import JobSection from './JobSection';
import JobDetailScreen from './JobDetailScreen';
import ScheduleSelectionScreen from './ScheduleSelectionScreen';

const getPlacements = (features: string[]) => {
  const placements: Placement[] = [];

  if (hasHVHCareersFeature(features) || hasSalariedAlumniJobs(features)) {
    placements.push(JobSection);
  }

  if (hasHVHCareersFeature(features)) {
    placements.push(JobSearchSubsection);
    placements.push(FilterScreen);
  }

  if (hasHVHCareersLoadMore(features)) {
    setJobCardLimit(JOB_CARDS_LOAD_MORE_REQUEST_LIMIT);
  }

  if (hasHVHCareersJobDetail(features)) {
    placements.push(JobDetailScreen);
    placements.push(ScheduleSelectionScreen);
  }

  return placements;
};

export default getPlacements;
