import React, {useCallback} from 'react';

import CheckboxGroup from '../../components/CheckboxGroup/CheckboxGroup';
import {useAppDispatch, useAppSelector} from '../../store';
import {REQUEST_SLICE_NAME} from '../../slices/constants/slice-constants';
import {setWeekShifts} from '../../slices/request.slice';
import {OptionType, ScheduleShifts} from '../../common/filterOptions/types';
import {initTimeOfWeek} from '../../common/filterOptions/constants/filterOption-constants';
import {FilterTitle} from '../../common/constant';

export const TimeWeekSelection = () => {
  const dispatch = useAppDispatch();
  const weekShifts = useAppSelector(state => {
    return state[REQUEST_SLICE_NAME].weekShifts;
  });

  const handleOnScheduleShiftChange = useCallback(
    (options: OptionType[]) => {
      dispatch(setWeekShifts(options as ScheduleShifts));
    },
    [dispatch],
  );

  return (
    <CheckboxGroup
      selectedValues={weekShifts}
      options={initTimeOfWeek}
      onChange={handleOnScheduleShiftChange}
      title={FilterTitle.TimeOfWeek}
    />
  );
};

export default React.memo(TimeWeekSelection);
