import {StyleSheet} from 'react-native';

const styles = StyleSheet.create({
  container: {
    flexDirection: 'column',
  },
  verticalContainer: {
    alignItems: 'flex-start',
  },
  horizontalContainer: {
    alignItems: 'center',
    flexDirection: 'row',
  },
});

export default styles;
