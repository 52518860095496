import {Log, LogLevel} from '@amzn/showtime';

import tenantId from './TenantId';

const Options = {
  maxBackendLogLevel: LogLevel.Debug,
};

const logging = Log(tenantId, Options);

export {logging};
