import React, {useMemo} from 'react';
import {H4, Text} from '@amzn/stencil-react-native-components/text';
import {Col} from '@amzn/atoz-native';
import {StencilFontSizeEnum, StencilSpacingEnum} from '@amzn/stencil-react-native-components/types';
import {Row} from '@amzn/stencil-react-native-components/layout';

import {useAppSelector} from '../../store';
import {JOB_DETAIL_SLICE_NAME, JOB_SCHEDULE_SLICE_NAME} from '../../slices/constants/slice-constants';
import {mapJobTitleDisplayValue} from '../../utils/commonHelper';
import JobCardChip from '../../components/JobCardChip/JobCardChip';
import {CommonColors} from '../../common/color';
import {
  getPayRateTextWithPrefix,
  isDragonStoneJobOrNot,
  getJobTypeText,
  getEmploymentTypeText,
} from '../../utils/JobCardHelper/JobCardHelper';
import {getAggregationBonusText, getJobDetailWorkAddress} from '../../utils/JobDetailHelper/JobDetailHelper';

const JobDetailCard = () => {
  const {jobDetail, scheduleAggregation} = useAppSelector(state => {
    return {...state[JOB_DETAIL_SLICE_NAME], ...state[JOB_SCHEDULE_SLICE_NAME]};
  });

  const {
    jobTitle,
    dataSource,
    currencyCode,
    totalPayRateMin,
    totalPayRateMax,
    jobBannerText,
    jobType,
    employmentType,
    jobIdNumber,
  } = jobDetail;

  const isDragonStoneJob = useMemo(() => isDragonStoneJobOrNot(dataSource), [dataSource]);
  const jobTitleDisplay = useMemo(() => mapJobTitleDisplayValue(jobTitle), [jobTitle]);

  const payRateDisplay = useMemo(() => {
    if (isDragonStoneJob && scheduleAggregation) {
      return getPayRateTextWithPrefix(
        scheduleAggregation.minPayRate,
        scheduleAggregation.maxPayRate,
        scheduleAggregation.currencyCode,
      );
    }

    return getPayRateTextWithPrefix(totalPayRateMin, totalPayRateMax, currencyCode);
  }, [totalPayRateMin, totalPayRateMax, currencyCode, isDragonStoneJob, scheduleAggregation]);

  const bannerData = useMemo(() => {
    if (isDragonStoneJob && scheduleAggregation) {
      return getAggregationBonusText(scheduleAggregation);
    }

    if (jobBannerText && jobBannerText.startsWith('$')) {
      return `Bonus Up to ${jobBannerText}`;
    }

    return jobBannerText;
  }, [isDragonStoneJob, jobBannerText, scheduleAggregation]);

  const jobTypeText = useMemo(() => {
    if (isDragonStoneJob && scheduleAggregation) {
      return getJobTypeText(scheduleAggregation.jobTypes);
    }

    return getJobTypeText(jobType);
  }, [jobType, scheduleAggregation, isDragonStoneJob]);

  const employmentTypeText = useMemo(() => {
    if (isDragonStoneJob && scheduleAggregation) {
      return getEmploymentTypeText(scheduleAggregation.employmentTypes);
    }

    return getEmploymentTypeText(employmentType);
  }, [employmentType, scheduleAggregation, isDragonStoneJob]);

  const jobDetailWorkAddress = useMemo(
    () => getJobDetailWorkAddress(jobDetail, scheduleAggregation),
    [jobDetail, scheduleAggregation],
  );

  return (
    <Col gridGap={StencilSpacingEnum.S200}>
      <Row>
        <H4 fontWeight="bold" accessibilityRole="header">
          {jobTitleDisplay}
        </H4>
      </Row>
      <Row>
        <Text fontSize={StencilFontSizeEnum.T400}>{payRateDisplay}</Text>
      </Row>
      <Row gridGap={StencilSpacingEnum.S200}>
        {isDragonStoneJob && scheduleAggregation?.scheduleCount ? (
          <JobCardChip
            chipColor={CommonColors.Neutral05}
            text={`${scheduleAggregation.scheduleCount} ${
              scheduleAggregation.scheduleCount > 1 ? 'shifts' : 'shift'
            } available`}
          />
        ) : null}
        {bannerData ? <JobCardChip chipColor={CommonColors.Blue10} text={bannerData} /> : null}
      </Row>
      <Row>
        <Text color={CommonColors.Neutral70}>{jobDetailWorkAddress}</Text>
      </Row>
      <Row>
        <Text color={CommonColors.Neutral70}>{jobTypeText + ' - ' + employmentTypeText}</Text>
      </Row>
      <Row>
        <Text color={CommonColors.Neutral70}>{`Job ID: ${jobIdNumber}`}</Text>
      </Row>
    </Col>
  );
};

export default JobDetailCard;
