import React, {useCallback, useEffect, useMemo} from 'react';
import {Button, ButtonVariant} from '@amzn/stencil-react-native-components/button';
import {Footer} from '@amzn/stencil-react-native-components/footer';
import {Flex, Hr, Col} from '@amzn/stencil-react-native-components/layout';
import {Spinner, SpinnerSize} from '@amzn/stencil-react-native-components/spinner';
import {StencilSpacingEnum} from '@amzn/stencil-react-native-components/types';
import {useActions, useCurrentUri} from '@amzn/showtime';
import {ScrollView} from 'react-native';

import {useAppDispatch, useAppSelector} from '../../store';
import {
  getSalesforceApplyUri,
  getUri,
  JobDetailPlacementURI,
  ScheduleSelectionScreenPlacementURI,
} from '../../utils/uriUtils/uriUtils';
import {getJobDetailAction} from '../../epics/getJobDetail.epic';
import {getJobSchedulesAction} from '../../epics/getJobSchedules.epic';
import {resetJobDetailState} from '../../slices/jobDetail.slice';
import {resetJobSchedulesState} from '../../slices/jobSchedules.slice';
import {isDragonStoneJobOrNot} from '../../utils/JobCardHelper/JobCardHelper';
import {JOB_DETAIL_SLICE_NAME, JOB_SCHEDULE_SLICE_NAME} from '../../slices/constants/slice-constants';
import {CommonColors} from '../../common/color';
import {emitMetric} from '../../utils/metricsHelper';
import {MetricNames} from '../../common/constant';

import JobDetailCard from './JobDetailCard';
import JobDescriptionAndQualification from './JobDescriptionAndQualification';
import HiringProcess from './HiringProcess';

const JobDetailScreen = () => {
  const {currentUri} = useCurrentUri();
  const {pushUri} = useActions();
  const dispatch = useAppDispatch();

  const jobId = currentUri.split(JobDetailPlacementURI.baseUri)[1];

  const {jobDetail, fetchingJobDetail, scheduleCards} = useAppSelector(state => {
    return {...state[JOB_DETAIL_SLICE_NAME], ...state[JOB_SCHEDULE_SLICE_NAME]};
  });

  const isDragonStoneJob = useMemo(() => isDragonStoneJobOrNot(jobDetail?.dataSource), [jobDetail?.dataSource]);

  const onPressRedirectToShiftSelection = useCallback(() => {
    if (isDragonStoneJob) {
      emitMetric(MetricNames.UserActionRedirectToShiftSelectionClick, jobId);

      pushUri(getUri(ScheduleSelectionScreenPlacementURI, {jobId}));
    } else {
      emitMetric(MetricNames.UserActionSalesforceJobApplyClick, jobId);

      pushUri(getSalesforceApplyUri(jobId));
    }
  }, [jobId, pushUri, isDragonStoneJob]);

  useEffect(() => {
    emitMetric(MetricNames.ScreenJobDetailScreenLoad, jobId);
  }, [jobId]);

  useEffect(() => {
    dispatch(getJobDetailAction({jobId}));

    return () => {
      dispatch(resetJobDetailState());
    };
  }, [jobId, dispatch]);

  useEffect(() => {
    if (jobDetail && isDragonStoneJob) {
      dispatch(getJobSchedulesAction({jobId}));
    }

    return () => {
      dispatch(resetJobSchedulesState());
    };
  }, [dispatch, jobDetail, jobId, isDragonStoneJob]);

  if (!jobDetail || fetchingJobDetail || (isDragonStoneJob && !scheduleCards)) {
    return <Spinner size={SpinnerSize.Medium} loadingText="Loading" showText margin={{top: 'S600', bottom: 'S600'}} />;
  }

  return (
    <Flex height="100%" width="100%">
      <ScrollView>
        <Flex backgroundColor={CommonColors.White} padding={[StencilSpacingEnum.S400, StencilSpacingEnum.S300]}>
          <Col gridGap={StencilSpacingEnum.S300}>
            <JobDetailCard />
            <Hr />
            <JobDescriptionAndQualification />
            <Hr />
            <HiringProcess />
          </Col>
        </Flex>
      </ScrollView>
      <Footer>
        <Button
          variant={ButtonVariant.Primary}
          testID="SelectAShiftAndApplyButton"
          onPress={onPressRedirectToShiftSelection}
        >
          {isDragonStoneJob ? 'Select a shift and apply' : 'Apply'}
        </Button>
      </Footer>
    </Flex>
  );
};

export default JobDetailScreen;
