import {Endpoints} from '@amzn/showtime';
import urlcat, {ParamMap} from 'urlcat';

import {getAccountMatchDomain, getCareerSiteDomain, getSalesForceDomain} from '../helper';
import {CareerSiteCMPID, Locale, HVHCareersCountryCode} from '../../common/constant';

type PlacementUri = {
  id: string;
  regexPath: RegExp | string;
  urlCatPath: string;
};

type PlacementUriWithBaseUri = PlacementUri & {baseUri: string};

// AtoZDiscovery will own this URI. This will link to the L3+ jobs tabs
export const AtoZSalariedJobsURI: {id: string} = {
  id: 'page-alumni-salaried-jobs',
};

export const JobDetailPlacementURI: PlacementUriWithBaseUri = {
  id: 'hiring-job',
  regexPath: '/hiring/job/[^/]+$',
  urlCatPath: '/hiring/job/:jobId',
  baseUri: '/hiring/job/',
};

export const JobSearchPlacementURI: PlacementUri = {
  id: 'hiring',
  // eslint-disable-next-line no-useless-escape, prettier/prettier
  regexPath: '\\/hiring(\\?.*)?$',
  urlCatPath: '/hiring',
};

export const JobSearchPanelPlacementURI: PlacementUri = {
  id: 'hiring-search',
  regexPath: '/hiring/search$',
  urlCatPath: '/hiring/search',
};

export const FilterScreenPlacementURI: PlacementUri = {
  id: 'hiring-filter',
  regexPath: '/hiring/filter$',
  urlCatPath: '/hiring/filter',
};

export const ScheduleSelectionScreenPlacementURI: PlacementUri = {
  id: 'hiring-job-schedule',
  regexPath: '/hiring/job/.*/schedule$',
  urlCatPath: '/hiring/job/:jobId/schedule',
};

export const getUriRegexString = (placement: PlacementUri) => {
  return Endpoints.ATOZ + placement.regexPath;
};

export const getUri = (placement: PlacementUri, params?: any) => {
  return urlcat(Endpoints.ATOZ, placement.urlCatPath, params);
};

export const getAccountMatchPageUri = (path: string, params: ParamMap) => {
  return urlcat(getAccountMatchDomain(), path, {
    ...params,
    locale: Locale.US,
    startFromAtoZ: 1,
    countryCode: HVHCareersCountryCode.US,
  });
};

export const getRedirectCareerSiteUri = (path: string, params: ParamMap) => {
  return urlcat(getCareerSiteDomain(), path, {...params, locale: Locale.US, cmpid: CareerSiteCMPID});
};

export const getSalesforceApplyUri = (jobId: string) => {
  const params = {
    isapply: 1,
    setlang: Locale.US,
    reqid: jobId,
    cmpid: CareerSiteCMPID,
  };

  return urlcat(getSalesForceDomain(), 'BBJobDetails', params);
};
