import * as React from 'react';
import Svg, {SvgProps, Path} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={24} height={24} fill="none" {...props}>
    <Path
      fill="#1768C9"
      fillRule="evenodd"
      d="M6 2.286V4.57H2c-1.105 0-2 1.024-2 2.286v14.857C0 22.977.895 24 2 24h20c1.105 0 2-1.023 2-2.286V6.857c0-1.262-.895-2.286-2-2.286h-4V2.286C18 1.023 17.105 0 16 0H8C6.895 0 6 1.023 6 2.286Zm2 4.571h14v3.429H2V6.857h6Z"
      clipRule="evenodd"
    />
  </Svg>
);

export default SvgComponent;
