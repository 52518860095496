import {Col} from '@amzn/atoz-native';
import React from 'react';

interface ExpanderSectionProps {
  children: string | JSX.Element | JSX.Element[] | (() => JSX.Element);
}

const ExpanderSection = ({children}: ExpanderSectionProps): JSX.Element => {
  return (
    <Col gridGap={10} padding={{right: 15, left: 15, bottom: 15}}>
      {children}
    </Col>
  );
};

export default ExpanderSection;
