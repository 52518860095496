import type {MobileTenantConfig} from '@amzn/showtime';

const mobileTenantConfig: MobileTenantConfig = {
  awsAccountId: '504507031758',
  bindleId: 'amzn1.bindle.resource.wz5a7aoylu76araoieka',
  cti: {
    category: 'A to Z Employee Self Service',
    type: 'HVHCareerSite',
    item: 'Issues',
  },
  emailList: 't-genesis@amazon.com',
  npmPackageName: '@amzn/atozmobile-tenant-hvhcareersite',
  posixGroup: 'hvh-careers-posix',
  projectName: 'AtoZHVHCareerSite',
  tenantId: 'HVHCareerSite',
};

export default mobileTenantConfig;
