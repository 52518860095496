import React, {useCallback, useEffect, useState} from 'react';
import {Button, ButtonVariant, Footer, Row} from '@amzn/atoz-native';
import {useActions} from '@amzn/showtime';
import {Spinner, SpinnerSize} from '@amzn/stencil-react-native-components/spinner';
import {Text} from '@amzn/stencil-react-native-components/text';
import {FlatList, StyleSheet, View} from 'react-native';

import {CommonColors} from '../../common/color';
import {MetricNames, SCHEDULE_SHIFT_SELECTION_LIMIT} from '../../common/constant';
import ScheduleCardComponent from '../../components/ScheduleCard/ScheduleCard';
import {JOB_SCHEDULE_SLICE_NAME} from '../../slices/constants/slice-constants';
import {useAppSelector} from '../../store';
import {getAccountMatchPageUri, getRedirectCareerSiteUri} from '../../utils/uriUtils/uriUtils';
import {emitMetric} from '../../utils/metricsHelper';
import {useIsAccountMatchPageEnabled} from '../../features';

const ScheduleSelectionScreen = () => {
  const {scheduleCards, fetchingJobSchedules, selectedScheduleId} = useAppSelector(state => {
    return {...state[JOB_SCHEDULE_SLICE_NAME]};
  });

  useEffect(() => {
    if (scheduleCards && scheduleCards.length > 0 && scheduleCards[0].jobId) {
      emitMetric(MetricNames.ScreenShiftSelectionScreenLoad, scheduleCards[0].jobId);
    }
  }, [scheduleCards]);

  const {pushUri} = useActions();
  const isAccountMatchPageEnabled = useIsAccountMatchPageEnabled();

  const handleOnApply = useCallback(() => {
    if (scheduleCards && scheduleCards[0].jobId && selectedScheduleId) {
      const selectedJobAndSchedule = {
        jobId: scheduleCards[0].jobId,
        scheduledId: selectedScheduleId,
      };
      const AESUri = getRedirectCareerSiteUri('/application/us/#pre-consent', selectedJobAndSchedule);

      emitMetric(MetricNames.UserActionApplyClick, JSON.stringify(selectedJobAndSchedule));

      if (isAccountMatchPageEnabled) {
        pushUri(getAccountMatchPageUri('', {redirectUrl: AESUri}));
      } else {
        pushUri(AESUri);
      }
    }
  }, [pushUri, scheduleCards, selectedScheduleId]);

  const [initialCards, setInitialCards] = useState(scheduleCards?.slice(0, SCHEDULE_SHIFT_SELECTION_LIMIT));

  const loadData = useCallback(() => {
    const tempCards = scheduleCards?.slice(initialCards.length, initialCards.length + SCHEDULE_SHIFT_SELECTION_LIMIT);

    setInitialCards([...initialCards, ...tempCards]);
  }, [scheduleCards, initialCards]);

  const renderFooter = useCallback(() => {
    if (!(initialCards && scheduleCards && initialCards.length < scheduleCards.length)) {
      return <></>;
    }
    return (
      <View style={{height: 80, backgroundColor: CommonColors.White, alignItems: 'center', justifyContent: 'center'}}>
        <Button testID="showMore-button" variant={ButtonVariant.Secondary} onPress={loadData}>
          Show more shifts
        </Button>
      </View>
    );
  }, [initialCards, loadData, scheduleCards]);

  if (!initialCards || !scheduleCards || fetchingJobSchedules) {
    return <Spinner size={SpinnerSize.Medium} loadingText="Loading" showText margin={{top: 'S600', bottom: 'S600'}} />;
  }

  return (
    <>
      {initialCards.length > 0 ? (
        <>
          <View style={{height: '90%', backgroundColor: CommonColors.White}}>
            <View style={headerStyles.container}>
              <Row alignItems="center" justifyContent="space-between">
                <Text
                  style={headerStyles.text}
                >{`Showing ${initialCards?.length} of ${scheduleCards?.length} shifts`}</Text>
              </Row>
            </View>
            <FlatList
              removeClippedSubviews={false}
              initialNumToRender={SCHEDULE_SHIFT_SELECTION_LIMIT}
              maxToRenderPerBatch={SCHEDULE_SHIFT_SELECTION_LIMIT}
              data={initialCards}
              renderItem={({item, index}) => (
                <ScheduleCardComponent scheduleCard={item} indexPosition={index} selectedId={selectedScheduleId} />
              )}
              keyExtractor={item => item.scheduleId}
              ListFooterComponent={renderFooter}
            />
          </View>
          <Footer>
            <Row style={footerStyles.footerContainer} gridGap={20} justifyContent="space-around">
              <View style={footerStyles.buttonContainer}>
                <Button
                  testID="SelectScheduleAndApply"
                  disabled={!selectedScheduleId}
                  variant={ButtonVariant.Primary}
                  onPress={handleOnApply}
                >
                  Apply
                </Button>
              </View>
            </Row>
          </Footer>
        </>
      ) : (
        <Text>No shifts found</Text>
      )}
    </>
  );
};
const headerStyles = StyleSheet.create({
  container: {
    paddingHorizontal: 16,
    backgroundColor: CommonColors.White,
    paddingBottom: 18,
    paddingTop: 18,
    borderBottomWidth: 1, // Add a bottom border
    borderBottomColor: CommonColors.Neutral20,
  },
  text: {
    color: CommonColors.Neutral70,
  },
});

const footerStyles = StyleSheet.create({
  footerContainer: {
    width: '100%',
  },
  buttonContainer: {
    width: '90%',
  },
});

export default ScheduleSelectionScreen;
