import React from 'react';
import {LinkCard} from '@amzn/stencil-react-native-components/card';
import {Col, Row} from '@amzn/stencil-react-native-components/layout';
import {Text} from '@amzn/stencil-react-native-components/text';
import {
  StencilFontSizeEnum,
  StencilFontWeightEnum,
  StencilSpacingEnum,
} from '@amzn/stencil-react-native-components/types';

import {CommonColors} from '../../common/color';
import {BorderEnum, MetricNames} from '../../common/constant';
import {ScheduleCard} from '../../common/types';
import {setSelectedScheduleId} from '../../slices/jobSchedules.slice';
import {useAppDispatch} from '../../store';
import JobCardChip from '../JobCardChip/JobCardChip';
import {emitMetric} from '../../utils/metricsHelper';

interface JobCardProps {
  scheduleCard: ScheduleCard;
  indexPosition: number;
  selectedId: string;
}

const ScheduleCardComponent = (props: JobCardProps) => {
  const {scheduleCard, selectedId} = props;
  const {scheduleText, surgePay, firstDayOnSite, firstDayOnSiteL10N, hoursPerWeek, totalPayRateL10N} = scheduleCard;
  const dispatch = useAppDispatch();

  return (
    <LinkCard
      flexDirection="column"
      margin={{top: 'S200', bottom: 'S200', right: 'S300', left: 'S300'}}
      accessibilityState={{selected: selectedId === scheduleCard.scheduleId}}
      accessibilityRole="button"
      onPress={() => {
        emitMetric(
          MetricNames.UserActionSelectShiftClick,
          JSON.stringify({jobId: scheduleCard.jobId, scheduledId: scheduleCard.scheduleId}),
        );

        dispatch(setSelectedScheduleId(scheduleCard.scheduleId));
      }}
      testID={'ScheduleCard-' + scheduleCard.scheduleId}
      backgroundColor={selectedId === scheduleCard.scheduleId ? CommonColors.Blue10 : CommonColors.White}
      style={{
        minHeight: 193,
        borderStyle: BorderEnum.SOLID,
      }}
      borderColor={selectedId === scheduleCard.scheduleId ? CommonColors.Blue70 : CommonColors.Neutral20}
      borderWidth={2}
    >
      <Col gridGap={StencilSpacingEnum.S200}>
        <Row gridGap={StencilSpacingEnum.S400}>
          <Text fontWeight={StencilFontWeightEnum.Bold} fontSize={StencilFontSizeEnum.T500}>
            {`${totalPayRateL10N}/hr`}
          </Text>
          {!surgePay && <JobCardChip chipColor={CommonColors.Blue10} text="Premium Pay" />}
        </Row>
        <Row>
          <Text fontSize={StencilFontSizeEnum.T300}>Schedule: </Text>
          <Text fontSize={StencilFontSizeEnum.T200}>{`${hoursPerWeek ? ` ${hoursPerWeek}hrs/wk` : ''}`}</Text>
        </Row>
        <Row>
          <Text fontSize={StencilFontSizeEnum.T200}>{scheduleText}</Text>
        </Row>
        <Row>
          <Text fontSize={StencilFontSizeEnum.T300}>Start date: </Text>
          <Text fontSize={StencilFontSizeEnum.T200}> {firstDayOnSiteL10N ? firstDayOnSiteL10N : firstDayOnSite}</Text>
        </Row>
      </Col>
    </LinkCard>
  );
};

export default ScheduleCardComponent;
