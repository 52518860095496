import {PreloadedState} from 'redux';
import {createEpicMiddleware} from 'redux-observable';
import {configureStore} from '@reduxjs/toolkit';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {CurriedGetDefaultMiddleware} from '@reduxjs/toolkit/dist/getDefaultMiddleware';

import createRootReducer from '../slices/root.slice';
import rootEpic from '../epics/root.epic';

const rootReducer = createRootReducer();

// Redux Middleware
const logger = () => (next: any) => (action: any) => next(action);
const epicMiddleware = createEpicMiddleware<any, any, any, any>();

export const setupStore = (preloadedState?: PreloadedState<RootState>) =>
  configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) => getDefaultMiddleware({serializableCheck: false}),
  });

const store = configureStore({
  reducer: rootReducer,
  // concat is required for typing
  middleware: (getDefaultMiddleware: CurriedGetDefaultMiddleware) =>
    getDefaultMiddleware({serializableCheck: false}).concat([epicMiddleware, logger]),
});

epicMiddleware.run(rootEpic);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export type AppStore = ReturnType<typeof setupStore>;

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export default store;
