import React, {useState} from 'react';
import WebView from 'react-native-webview';
import {Col, Hr} from '@amzn/stencil-react-native-components/layout';
import {Text} from '@amzn/stencil-react-native-components/text';
import {StencilSpacingEnum} from '@amzn/stencil-react-native-components/types';

import {useAppSelector} from '../../store';
import {JOB_DETAIL_SLICE_NAME} from '../../slices/constants/slice-constants';
import {CommonColors} from '../../common/color';

const generateHtml = (content: string) => `
<!DOCTYPE html>
<html>
  <head>
    <title>Web View</title>
    <meta http-equiv="content-type" content="text/html; charset=utf-8">
    <meta name="viewport" content="width=device-width, user-scalable=no">
    <style>
      body {
        margin: 0;
        padding: 0;
      }
    </style>
  </head>
  <body>
    ${content}
  </body>
</html>
`;

const postContentHeightScript = 'window.ReactNativeWebView.postMessage(document.body.scrollHeight)';

const JobDescriptionAndQualification = () => {
  const {jobDetail} = useAppSelector(state => {
    return {...state[JOB_DETAIL_SLICE_NAME]};
  });

  const {jobDescription, jobQualification, complianceText} = jobDetail;
  const [jobDescriptionHeight, setJobDescriptionHeight] = useState(0);
  const [jobQualificationHeight, setJobQualificationHeight] = useState(0);

  return (
    <Col gridGap={StencilSpacingEnum.S400}>
      {jobDescription && (
        <Col backgroundColor={CommonColors.White} gridGap={StencilSpacingEnum.S200}>
          <Text fontWeight="bold" accessibilityRole="header">
            Job Description
          </Text>
          <WebView
            scrollEnabled={false}
            source={{html: generateHtml(jobDescription)}}
            style={{height: jobDescriptionHeight, width: '100%', opacity: 0.99}} // opacity is resolution to android app crash when using webview
            onMessage={event => setJobDescriptionHeight(Number(event.nativeEvent.data))}
            injectedJavaScript={postContentHeightScript}
          />
          {complianceText && <Text>{complianceText}</Text>}
        </Col>
      )}
      {jobDescription && jobQualification && <Hr />}
      {jobQualification && (
        <Col backgroundColor={CommonColors.White} gridGap={StencilSpacingEnum.S200}>
          <Text fontWeight="bold" accessibilityRole="header">
            Job Qualification
          </Text>
          <WebView
            scrollEnabled={false}
            source={{html: generateHtml(jobQualification)}}
            style={{height: jobQualificationHeight, width: '100%', opacity: 0.99}} // opacity is resolution to android app crash when using webview
            onMessage={event => setJobQualificationHeight(Number(event.nativeEvent.data))}
            injectedJavaScript={postContentHeightScript}
          />
        </Col>
      )}
    </Col>
  );
};

export default JobDescriptionAndQualification;
