import React, {useCallback, useEffect, useMemo} from 'react';
import {Button, ButtonVariant} from '@amzn/atoz-native';
import {useCurrentUri, useUserContext} from '@amzn/showtime';
import {FlatList, View} from 'react-native';
import {Spinner, SpinnerSize} from '@amzn/stencil-react-native-components/spinner';
import {createSelector} from '@reduxjs/toolkit';

import {jobSearchRequestGenerator, generateFilterPayload} from '../../utils/requestService';
import {getJobCardsByLocationAction} from '../../epics/getJobs.epic';
import JobCardComponent from '../../components/JobCard/JobCard';
import {useJobRequest} from '../../common/hooks/useJobRequest';
import SearchPanel from '../../components/SearchPanel/SearchPanel';
import FilterBar from '../../components/FilterBar/FilterBar';
import {useAppDispatch, useAppSelector} from '../../store';
import {fetchDefaultAddress, receiveDefaultAddress, selectLocationState} from '../../slices/location.slice';
import JobNotFound from '../../components/JobNotFound/JobNotFound';
import {
  homePageIngressParamAddress,
  homePageIngressParamFilter,
  JOB_CARDS_REQUEST_LIMIT,
  MetricNames,
} from '../../common/constant';
import {wrapSubSectionCallWithTrace} from '../../utils/metricsHelper';
import {getUrlQueryParam, parseShiftsFromURLParams} from '../../common/utils';
import {setScheduleShift, setWeekShifts} from '../../slices/request.slice';
import {getLocationByAddressAction} from '../../epics/getLocation.epic';
import {FilterKeys} from '../../common/filterOptions/constants/filterOption-constants';
import {CommonColors} from '../../common/color';
import {useIsLoadMoreFeatureEnabled} from '../../features';
import {selectJobCardState} from '../../slices/jobCards.slice';

const jobSearchSubsectionState = createSelector(
  [selectJobCardState, selectLocationState],
  ({fetchingJobCards, nextToken, fetchingMoreJobCards, jobCards}, locationState) => {
    return {fetchingJobCards, nextToken, fetchingMoreJobCards, jobCards, location: locationState};
  },
);

const JobSearchSubsection = () => {
  const {fetchingJobCards, nextToken, fetchingMoreJobCards, jobCards, location} =
    useAppSelector(jobSearchSubsectionState);
  const dispatch = useAppDispatch();
  const {employeeId} = useUserContext();
  const jobSearchRequest = useJobRequest();
  const {currentUri, isReady} = useCurrentUri();
  const isLoadMoreEnabled = useIsLoadMoreFeatureEnabled();

  const requestJobCards = useCallback(
    jobSearchRequest => {
      const jobSearchRequestResult = jobSearchRequestGenerator(jobSearchRequest);

      dispatch(getJobCardsByLocationAction({request: jobSearchRequestResult}));
    },
    [dispatch],
  );

  useEffect(() => {
    if (!isReady) return;
    wrapSubSectionCallWithTrace(MetricNames.SubSectionJobSearchLoad, () => {
      // parse filters from homepage ingress
      const filterString = getUrlQueryParam(currentUri, homePageIngressParamFilter);
      const {scheduleShifts, weekShifts} = parseShiftsFromURLParams(filterString);

      if (scheduleShifts && scheduleShifts.length) dispatch(setScheduleShift(scheduleShifts));
      if (weekShifts && weekShifts.length) dispatch(setWeekShifts(weekShifts));

      // parse address from homepage ingress, if not provided, fetch default address
      const address = getUrlQueryParam(currentUri, homePageIngressParamAddress);

      if (address) {
        dispatch(receiveDefaultAddress({address}));

        // getLocationByAddressAction trigger fetching job cards, needs to update fetchJobCards state to see loading animation
        dispatch(getLocationByAddressAction({address}));
      } else if (!location.defaultAddressLoaded) {
        dispatch(fetchDefaultAddress({employeeId}));
      } else if ((scheduleShifts && scheduleShifts.length) || (weekShifts && weekShifts.length)) {
        const newJobSearchRequest = {
          ...jobSearchRequest,
          containFilters: [
            ...jobSearchRequest.containFilters.filter(filter => filter.key !== FilterKeys.SCHEDULE_SHIFTS),
            generateFilterPayload(FilterKeys.SCHEDULE_SHIFTS, [...scheduleShifts, ...weekShifts]),
          ],
        };

        requestJobCards(newJobSearchRequest);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUri, isReady, dispatch]);

  const handleOnSubmit = useCallback(() => {
    requestJobCards(jobSearchRequest);
  }, [requestJobCards, jobSearchRequest]);

  const showMoreJobPress = useCallback(() => {
    requestJobCards({...jobSearchRequest, nextToken});
  }, [requestJobCards, jobSearchRequest, nextToken]);

  const renderFooter = useCallback(() => {
    if ((jobCards && jobCards.length < JOB_CARDS_REQUEST_LIMIT) || !nextToken) {
      return <></>;
    }

    return (
      <View style={{height: 80, backgroundColor: CommonColors.White, alignItems: 'center', justifyContent: 'center'}}>
        <View style={{width: '40%'}}>
          <Button testID="showMore-button" variant={ButtonVariant.Secondary} onPress={showMoreJobPress}>
            {/* @ts-ignore  Button does not allow non-text even though the render supports it */}
            <>{fetchingMoreJobCards ? <Spinner loadingText={''} /> : 'Show more jobs'}</>
          </Button>
        </View>
      </View>
    );
  }, [fetchingMoreJobCards, nextToken, jobCards, showMoreJobPress]);

  const listFooter = useMemo(() => {
    return isLoadMoreEnabled ? renderFooter : undefined;
  }, [isLoadMoreEnabled, renderFooter]);

  return (
    <>
      <SearchPanel onSubmit={handleOnSubmit} />
      <FilterBar />
      {!jobCards || fetchingJobCards ? (
        <Spinner size={SpinnerSize.Medium} loadingText="Loading" showText margin={{top: 'S600', bottom: 'S600'}} />
      ) : jobCards.length > 0 ? (
        <>
          <FlatList
            removeClippedSubviews={false}
            initialNumToRender={JOB_CARDS_REQUEST_LIMIT}
            maxToRenderPerBatch={JOB_CARDS_REQUEST_LIMIT}
            data={jobCards}
            renderItem={({item, index}) => <JobCardComponent jobCard={item} indexPosition={index} />}
            keyExtractor={item => item.jobId}
            ListFooterComponent={listFooter}
          />
        </>
      ) : (
        <JobNotFound />
      )}
    </>
  );
};

export default JobSearchSubsection;
