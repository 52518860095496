const geoInfoResponseItems = `
country
lat
lng
postalCode
label
municipality
region
subRegion
addressNumber
`;

const jobCardsResponse = `
nextToken
jobCards {
    jobId
    language
    dataSource
    requisitionType
    jobTitle
    jobType
    employmentType
    city
    state
    postalCode
    locationName
    totalPayRateMin
    totalPayRateMax
    tagLine
    bannerText
    image
    jobPreviewVideo
    distance
    featuredJob
    bonusJob
    bonusPay
    scheduleCount
    currencyCode
    geoClusterDescription
    surgePay
    jobTypeL10N
    employmentTypeL10N
    bonusPayL10N
    surgePayL10N
    totalPayRateMinL10N
    totalPayRateMaxL10N
    distanceL10N
    monthlyBasePayMin
    monthlyBasePayMinL10N
    monthlyBasePayMax
    monthlyBasePayMaxL10N
    jobContainerJobMetaL1
    virtualLocation
    poolingEnabled
}`;

const jobDetailResponse = `
agencyName
jobId
language
dataSource
requisitionType
jobIdNumber
jobTitle
jobType
jobTypeL10N
employmentType
employmentTypeL10N
fullAddress
country
city
state
postalCode
totalPayRateMin
totalPayRateMinL10N
totalPayRateMax
totalPayRateMaxL10N
currencyCode
tagLine
image
jobPreviewVideo
distance
featuredJob
bonusJob
bonusPayL10N
jobDescription
jobQualification
careerPortalURL
blackbirdPortalURL
postingStatus
uiPath
siteId
locationDescription
locationName
jobBannerText
geoClusterId
geoClusterName
geoClusterRegion
geoClusterDescription
locationCode
complianceText
requiredLanguage
monthlyBasePay
jobContainerJobMetaL1
mostRecentPostedDate
mostRecentUnpostedDate
address
poolingEnabled
`;

const scheduleCardsResponse = `
nextToken
scheduleCards {
    hireStartDate
    address
    basePay
    bonusSchedule
    city
    currencyCode
    dataSource
    distance
    employmentType
    externalJobTitle
    featuredSchedule
    firstDayOnSite
    hoursPerWeek
    image
    jobId
    jobPreviewVideo
    language
    postalCode
    priorityRank
    scheduleBannerText
    scheduleId
    scheduleText
    scheduleType
    signOnBonus
    state
    surgePay
    tagLine
    geoClusterId
    geoClusterName
    siteId
    scheduleBusinessCategory
    totalPayRate
    financeWeekStartDate
    laborDemandAvailableCount
    scheduleBusinessCategoryL10N
    firstDayOnSiteL10N
    financeWeekStartDateL10N
    scheduleTypeL10N
    employmentTypeL10N
    basePayL10N
    signOnBonusL10N
    totalPayRateL10N
    distanceL10N
    requiredLanguage
    monthlyBasePay
    monthlyBasePayL10N
    vendorKamName
    vendorId
    vendorName
    kamPhone
    kamCorrespondenceEmail
    kamStreet
    kamCity
    kamDistrict
    kamState
    kamCountry
    kamPostalCode
}
`;

export const queryGeoInfoByAddressQuery = `query queryGeoInfoByAddress($geoAddressQueryRequest: GeoAddressQueryRequest!) {
        queryGeoInfoByAddress(geoAddressQueryRequest: $geoAddressQueryRequest) {
            ${geoInfoResponseItems}
        }
    }`;

export const searchJobCardsByLocationQuery = `query searchJobCardsByLocation($searchJobRequest: SearchJobRequest!) {
        searchJobCardsByLocation(searchJobRequest: $searchJobRequest) {
            ${jobCardsResponse}
        }
    }`;

export const getJobDetailQuery = `query getJobDetail($getJobDetailRequest: GetJobDetailRequest!) {
        getJobDetail(getJobDetailRequest: $getJobDetailRequest) {
            ${jobDetailResponse}
        }
    }`;

export const searchScheduleCardsQuery = `query searchScheduleCards($searchScheduleRequest: SearchScheduleRequest!) {
        searchScheduleCards(searchScheduleRequest: $searchScheduleRequest) {
            ${scheduleCardsResponse}
        }
    }`;
