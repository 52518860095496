import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';

import {ScheduleAggregation, ScheduleCards} from '../common/types';

import {JOB_SCHEDULE_SLICE_NAME} from './constants/slice-constants';
import {RootState} from './root.slice';

export interface JobSchedulesState {
  scheduleCards?: ScheduleCards;
  fetchingJobSchedules: boolean;
  selectedScheduleId?: string;
  scheduleAggregation?: ScheduleAggregation;
}

export const initialJobScheduleState: JobSchedulesState = {
  fetchingJobSchedules: false,
  selectedScheduleId: undefined,
};

export const selectJobScheduleState = (state: RootState) => state[JOB_SCHEDULE_SLICE_NAME];

const {actions, reducer} = createSlice({
  name: JOB_SCHEDULE_SLICE_NAME,
  initialState: initialJobScheduleState,
  reducers: {
    resetJobSchedulesState: () => {
      return initialJobScheduleState;
    },
    fetchJobSchedules: state => {
      state.fetchingJobSchedules = true;
      state.selectedScheduleId = undefined;
    },
    setSelectedScheduleId: (state: JobSchedulesState, {payload}: PayloadAction<string>) => {
      state.selectedScheduleId = payload;
    },
    onReceiveJobSchedules: (
      state: JobSchedulesState,
      {payload: {scheduleCards, scheduleAggregation}}: PayloadAction<any>,
    ) => {
      state.scheduleCards = scheduleCards;
      state.fetchingJobSchedules = false;
      state.scheduleAggregation = scheduleAggregation;
      state.selectedScheduleId = undefined;
    },
  },
});

export const {resetJobSchedulesState, fetchJobSchedules, setSelectedScheduleId, onReceiveJobSchedules} = actions;

export default reducer;
