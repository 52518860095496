import {concat, from, Observable, of} from 'rxjs';
import {switchMap, filter, withLatestFrom, catchError} from 'rxjs/operators';
import {ApolloQueryResult} from '@apollo/client';
import {createAction} from '@reduxjs/toolkit';
import {StateObservable} from 'redux-observable';

import {searchJobCardsByLocation} from '../api/appSyncAPI/appSyncApi';
import {SearchJobCardsByLocationRequest, SearchJobCardsByLocationResponseGQL} from '../api/appSyncAPI/types';
import {emitMetric} from '../utils/metricsHelper';
import {MetricNames} from '../common/constant';
import {
  fetchJobCards,
  fetchMoreJobCards,
  onReceiveJobCardsByLocation,
  onReceiveJobCardsByNextToken,
} from '../slices/jobCards.slice';
import {RootState} from '../slices/root.slice';
import {addAlert} from '../slices/alerts.slice';

export enum JobCardsActionTypes {
  GET_JOB_CARD = 'jobCards/getJobCardsAction',
}

export interface getJobCardPayload {
  request: SearchJobCardsByLocationRequest;
}

export const getJobCardsByLocationAction = createAction<getJobCardPayload>(JobCardsActionTypes.GET_JOB_CARD);

export const getJobCardsByLocationEpic = (action$: Observable<any>, state$: StateObservable<RootState>) =>
  action$.pipe(
    filter(getJobCardsByLocationAction.match),
    withLatestFrom(state$),
    switchMap(([actions]: [any, RootState]): Observable<any> => {
      const isFetchingMoreCards = Boolean(actions.payload.request.nextToken);
      const fetchingAction = isFetchingMoreCards ? fetchMoreJobCards : fetchJobCards;

      return concat(
        of(fetchingAction()),
        from(searchJobCardsByLocation(actions.payload.request)).pipe(
          switchMap(async (response: ApolloQueryResult<SearchJobCardsByLocationResponseGQL>) => {
            if (!response?.data?.searchJobCardsByLocation) {
              throw new Error('Unable to retrieve your jobs');
            }

            const {jobCards, nextToken} = response.data.searchJobCardsByLocation;

            emitMetric(MetricNames.APIResponseJobsCount, jobCards.length.toString());
            const receiveAction = isFetchingMoreCards ? onReceiveJobCardsByNextToken : onReceiveJobCardsByLocation;

            return receiveAction({
              jobCards,
              nextToken,
            });
          }),

          catchError(() => {
            return [addAlert({alertMessage: 'Unable to retrieve jobs'})];
          }),
        ),
      );
    }),
  );
