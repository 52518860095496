import {ApolloQueryResult, gql} from '@apollo/client';
import {API} from '@amzn/showtime';

import {wrapApiWithMetrics} from '../../utils/metricsHelper';
import {MetricNames} from '../../common/constant';

import {queryLatestJobAddressQuery} from './queries';
import {queryLatestJobAddressResponseGQL} from './types';

export const queryLatestJobAddress = async (
  employeeId: string,
): Promise<ApolloQueryResult<queryLatestJobAddressResponseGQL>> => {
  const apiFunc = async () => {
    const concertClient = await API.getConcertClient();

    return concertClient.query({query: gql(queryLatestJobAddressQuery), variables: {employeeId}});
  };

  return wrapApiWithMetrics(MetricNames.ApiQueryLatestJobAddress, apiFunc);
};
