import * as React from 'react';
import Svg, {SvgProps, Circle, Path} from 'react-native-svg';
const SvgComponent = (props: SvgProps) => (
  <Svg width={56} height={57} fill="none" {...props}>
    <Circle cx={28} cy={28.749} r={28} fill="#D7EBFB" />
    <Path stroke="#161E2D" d="M15.787 13.487h25.16v6.474h-25.16zM15.787 23.459h25.16v6.474h-25.16z" />
    <Path
      fill="#D7EBFB"
      d="m34.406 47.998 1.84-5.73v-7.734a1.64 1.64 0 0 0-1.64-1.64 1.652 1.652 0 0 0-1.656 1.64v-.672a1.647 1.647 0 0 0-2.807-1.162 1.647 1.647 0 0 0-.483 1.162v-.49a1.647 1.647 0 0 0-3.294 0v-4.934a1.65 1.65 0 0 0-1.643-1.647 1.65 1.65 0 0 0-1.647 1.647v10.085l-1.361-2.76a1.867 1.867 0 0 0-3.53 1.16l1.822 6.672 3.195 4.403"
    />
    <Path
      stroke="#2C3746"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="m34.406 47.998 1.84-5.73v-7.734a1.64 1.64 0 0 0-1.64-1.64 1.652 1.652 0 0 0-1.656 1.64v-.672a1.647 1.647 0 0 0-2.807-1.162 1.647 1.647 0 0 0-.483 1.162v-.49a1.647 1.647 0 0 0-3.294 0v-4.934a1.65 1.65 0 0 0-1.643-1.647 1.65 1.65 0 0 0-1.647 1.647v10.085l-1.361-2.76a1.867 1.867 0 0 0-3.53 1.16l1.822 6.672 3.195 4.403"
    />
    <Path stroke="#000" d="m18.276 16.45 1.223 1.223 1.833-2.444M18.276 26.423l1.223 1.222 1.833-2.444" />
  </Svg>
);

export default SvgComponent;
