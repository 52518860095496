import {createSelector} from '@reduxjs/toolkit';
import {useMemo} from 'react';

import {useAppSelector} from '../../store';
import {selectJobCardState} from '../../slices/jobCards.slice';
import {selectRequestState} from '../../slices/request.slice';
import {selectLocationState} from '../../slices/location.slice';
import {createFilters} from '../../utils/requestService';

export const selectFilterState = createSelector(
  [selectRequestState, selectJobCardState, selectLocationState],
  (
    {firstDayOnSite, jobTitle, jobType, keywords, scheduleShift, weekShifts, employmentType, distance, sorter},
    {jobCards},
    locationState,
  ) => {
    return {
      firstDayOnSite,
      jobCards,
      jobTitle,
      jobType,
      keywords,
      location: {lng: locationState.lng, lat: locationState.lat},
      scheduleShift,
      weekShifts,
      employmentType,
      distance,
      sorter,
    };
  },
);

export const useJobRequest = () => {
  const {
    distance,
    employmentType,
    firstDayOnSite,
    jobTitle,
    jobType,
    keywords,
    location,
    scheduleShift,
    sorter,
    weekShifts,
  } = useAppSelector(selectFilterState);

  const {dateFilters, containFilters, equalFilters, geoQueryClause, sorters, rangeFilters} = useMemo(() => {
    return createFilters({
      weekShifts,
      scheduleShift,
      jobTitle,
      jobType,
      firstDayOnSite,
      employmentType,
      distance,
      sorter,
    });
  }, [scheduleShift, weekShifts, jobTitle, jobType, firstDayOnSite, employmentType, distance, sorter]);

  const request = useMemo(
    () => ({containFilters, dateFilters, equalFilters, keywords, location, geoQueryClause, sorters, rangeFilters}),
    [dateFilters, containFilters, keywords, location, equalFilters, geoQueryClause, sorters, rangeFilters],
  );

  return request;
};
