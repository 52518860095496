import {CommonColors} from '../../color';
import {CurrencyCodeMap, GeoUnit, MaxDistance} from '../../constant';
import {PayRateConfig, StartDateOptionType, WorkHoursConfig} from '../../types';
import {
  JobRoles,
  CommonFilterOption,
  FilterOptions,
  LanguageRequirementOptions,
  LengthOfEmploymentOptions,
  ScheduleShifts,
} from '../types';

export enum FilterKeys {
  DISTANCE = 'distance',
  START_DATE = 'startDate',
  BONUS = 'bonus',
  PAYRATE = 'payRate',
  HRS_PER_WEEK = 'hoursPerWeek',
  WORK_HOUR = 'workHour',
  JOB_TYPE = 'jobType',
  LENGTH_OF_EMPLOYMENT = 'lengthOfEmployment',
  SCHEDULE_SHIFTS = 'scheduleShift',
  JOB_TITLE = 'jobTitle',
  FIRST_DAY_ON_SITE = 'firstDayOnSite',
  EMPLOYMENT_TYPE = 'employmentType',
}

export enum FieldName {
  TOTAL_MAX_PAY_RATE = 'totalPayRateMax',
}

const markStyle: React.CSSProperties = {
  marginTop: 10,
  color: CommonColors.Neutral70,
};

export enum HoursPerWeekText {
  ANY = 'Any',
  PART_TIME_FULL_TIME = 'Part time / Full time',
  PART_TIME = 'Part time',
  FULL_TIME = 'Full time',
  REDUCED_TIME = 'Reduced time',
  FLEX_TIME = 'Flex time',
}

export enum SortOptionValues {
  PAY_RATE_HIGH_TO_LOW = 'pay-rate-high-to-low',
  PAY_RATE_LOW_TO_HIGH = 'pay-rate-low-to-high',
}

export enum WorkHourOptionValues {
  FULL_TIME = 'Full-time',
  PART_TIME = 'Part-time',
}

export enum StartDateOptionValue {
  One_TO_TWO_WEEKS = 'One_TO_TWO_WEEKS',
  TWO_TO_THREE_WEEKS = 'TWO_TO_THREE_WEEKS',
  MORE_THAN_THREE_WEEK = 'MORE_THAN_THREE_WEEK',
}

export enum ScheduleShiftValue {
  EarlyMorning = 'EarlyMorning',
  Daytime = 'Daytime',
  Evening = 'Evening',
  Night = 'Night',
  Weekday = 'Weekday',
  Weekend = 'Weekend',
}

export enum EnUsRoleProperty {
  FULFILMENT_CENTER_ASSOCIATE = 'Amazon Fulfillment Center Warehouse Associate',
  SORTATION_CENTER_ASSOCIATE = 'Amazon Sortation Center Warehouse Associate',
  DELIVERY_STATION_ASSOCIATE = 'Amazon Delivery Station Warehouse Associate',
  DISTRIBUTION_CENTER_ASSOCIATE = 'Amazon Distribution Center Associate',
  GROCERY_WAREHOUSE_ASSOCIATE = 'Amazon Grocery Warehouse Associate',
  CENTRAL_OPERATIONS_ASSOCIATE = 'Amazon Central Operations Associate',
  AIR_ASSOCIATE = 'Amazon Air Associate',
  LOCKER_CUSTOMER_ASSOCIATE = 'Amazon Locker+ Customer Service Associate',
  HOURLY_TEAM_MEMBER = 'Amazon Hourly Team Member',
  WAREHOUSE_TEAM_MEMBER = 'Amazon Warehouse Team Member',
  XL_WAREHOUSE_ASSOCIATE = 'Amazon XL Warehouse Associate',
  SCOUT = 'Amazon Scout Robotics Associate',
  CUSTOMER_SERVICE_ASSOCIATE = 'Customer Service Associate',
  REMOTE_CUSTOMER_SERVICE_ASSOCIATE = 'Remote Customer Service Associate',
  DELIVERY_STATION_CUSTOMER_SERVICE_ASSOCIATE = 'Delivery Station Customer Service Associate',
}

export const initScheduleShifts: ScheduleShifts = [
  {value: ScheduleShiftValue.EarlyMorning, shownValue: 'Early morning'},
  {value: ScheduleShiftValue.Daytime, shownValue: 'Day time'},
  {value: ScheduleShiftValue.Evening, shownValue: 'Evening'},
  {value: ScheduleShiftValue.Night, shownValue: 'Night'},
];

export const initTimeOfWeek: ScheduleShifts = [
  {value: ScheduleShiftValue.Weekday, shownValue: 'Weekday'},
  {value: ScheduleShiftValue.Weekend, shownValue: 'Weekend'},
];

export const startDateOptions: StartDateOptionType[] = [
  {value: null, shownValue: 'As soon as possible'},
  {value: StartDateOptionValue.One_TO_TWO_WEEKS, shownValue: 'Within 1-2 weeks'},
  {value: StartDateOptionValue.TWO_TO_THREE_WEEKS, shownValue: 'Within 2-3 weeks'},
  {value: StartDateOptionValue.MORE_THAN_THREE_WEEK, shownValue: 'After 3 weeks'},
];

export const workHoursConfigDefault: WorkHoursConfig = {
  initHoursPerWeek: [0, 40],
  summaryText: [
    [[30], HoursPerWeekText.FULL_TIME],
    [[30, 30], HoursPerWeekText.PART_TIME_FULL_TIME],
    [[], HoursPerWeekText.PART_TIME],
  ],
  showLabel: true,
  step: 5,
  min: 0,
  max: 40,
  defaultValue: [0, 40],
  value: [0, 40],
  marks: {
    0: {
      style: markStyle,
      label: 0,
    },
    10: {
      style: markStyle,
      label: 10,
    },
    20: {
      style: markStyle,
      label: 20,
    },
    30: {
      style: markStyle,
      label: 30,
    },
    40: {
      style: markStyle,
      label: 40,
    },
  },
};

export const payRateConfigDefault: PayRateConfig = {
  initPayRate: [0, 80],
  currencyCode: CurrencyCodeMap.USD,
  showLabel: true,
  step: 10,
  min: 0,
  max: 80,
  defaultValue: [0, 80],
  value: [0, 80],
  marks: {
    0: {
      style: markStyle,
      label: 0,
    },
    20: {
      style: markStyle,
      label: 20,
    },
    40: {
      style: markStyle,
      label: 40,
    },
    60: {
      style: markStyle,
      label: 60,
    },
    80: {
      style: markStyle,
      label: 80,
    },
  },
};

export const USJobRoles: JobRoles = [
  {
    value: EnUsRoleProperty.FULFILMENT_CENTER_ASSOCIATE,
    shownValue: 'Fulfillment Center Warehouse Associate',
  },
  {
    value: EnUsRoleProperty.SORTATION_CENTER_ASSOCIATE,
    shownValue: 'Sortation Center Warehouse Associate',
  },
  {
    value: EnUsRoleProperty.DELIVERY_STATION_ASSOCIATE,
    shownValue: 'Delivery Station Warehouse Associate',
  },
  {
    value: EnUsRoleProperty.DISTRIBUTION_CENTER_ASSOCIATE,
    shownValue: 'Distribution Center Associate',
  },
  {
    value: EnUsRoleProperty.GROCERY_WAREHOUSE_ASSOCIATE,
    shownValue: 'Grocery Warehouse Associate',
  },
  {
    value: EnUsRoleProperty.CENTRAL_OPERATIONS_ASSOCIATE,
    shownValue: 'Central Operations Associate',
  },
  {
    value: EnUsRoleProperty.AIR_ASSOCIATE,
    shownValue: 'Air Associate',
  },
  {
    value: EnUsRoleProperty.LOCKER_CUSTOMER_ASSOCIATE,
    shownValue: 'Locker+ Customer Service Associate',
  },
  {
    value: EnUsRoleProperty.HOURLY_TEAM_MEMBER,
    shownValue: 'Hourly Team Member',
  },
  {
    value: EnUsRoleProperty.WAREHOUSE_TEAM_MEMBER,
    shownValue: 'Warehouse Team Member',
  },
  {
    value: EnUsRoleProperty.XL_WAREHOUSE_ASSOCIATE,
    shownValue: 'XL Warehouse Associate',
  },
  {
    value: EnUsRoleProperty.SCOUT,
    shownValue: 'Scout Robotics Associate',
  },
  {
    value: EnUsRoleProperty.CUSTOMER_SERVICE_ASSOCIATE,
    shownValue: 'Customer Service Associate',
  },
  {
    value: EnUsRoleProperty.REMOTE_CUSTOMER_SERVICE_ASSOCIATE,
    shownValue: 'Remote Customer Service Associate',
  },
  {
    value: EnUsRoleProperty.DELIVERY_STATION_CUSTOMER_SERVICE_ASSOCIATE,
    shownValue: 'Delivery Station Customer Service Associate',
  },
];

export const CommonFilterOptions: CommonFilterOption = {
  distanceOptions: [
    {value: MaxDistance, shownValue: 'Any'},
    {value: 3, shownValue: 'Within 3 miles', unit: GeoUnit.MILE},
    {value: 10, shownValue: 'Within 10 miles', unit: GeoUnit.MILE},
    {value: 15, shownValue: 'Within 15 miles', unit: GeoUnit.MILE},
    {value: 20, shownValue: 'Within 20 miles', unit: GeoUnit.MILE},
    {
      value: 30,
      shownValue: 'Within 30 miles',
      unit: GeoUnit.MILE,
      defaultValue: true,
    },
    {value: 50, shownValue: 'Within 50 miles', unit: GeoUnit.MILE},
  ],
  workHourOptions: [
    {value: null, shownValue: 'Any', shortValue: 'Any'},
    {value: WorkHourOptionValues.FULL_TIME, shownValue: 'Full time (30+ hours)', shortValue: 'Full time'},
    {value: WorkHourOptionValues.PART_TIME, shownValue: 'Part time (0-30 hours)', shortValue: 'Part time'},
  ],
  sortOptions: [
    {value: null, shownValue: 'Distance from home'},
    {value: SortOptionValues.PAY_RATE_HIGH_TO_LOW, shownValue: 'Pay rate: High to Low'},
    {value: SortOptionValues.PAY_RATE_LOW_TO_HIGH, shownValue: 'Pay rate: Low to High'},
  ],
};

export const USLengthOfEmploymentOptions: LengthOfEmploymentOptions = [
  {
    value: null,
    shownValue: 'Any',
    longValue: 'Any duration',
    selectedAll: false,
  },
  {
    value: 'Regular',
    shownValue: 'Regular',
    shortValue: 'Regular',
    longValue: 'Regular - more than 3 months',
  },
  {
    value: 'Seasonal',
    shownValue: 'Seasonal',
    shortValue: 'Seasonal',
    longValue: 'Seasonal - 3 months or less',
  },
];

export const USLanguageRequirementOptions: LanguageRequirementOptions = [
  {value: null, shownValue: 'Any'},
  {value: 'en-US', shownValue: 'English'},
  {value: 'es-US', shownValue: 'Español'},
];

export const USFilterOptions: FilterOptions = {
  ...CommonFilterOptions,
  lengthOfEmploymentOptions: USLengthOfEmploymentOptions,
  languageRequirementOptions: USLanguageRequirementOptions,
};
