import {getCurrencyFromCurrencyCode} from '../commonHelper';
import {isRemoteJob, isSalesForceJobOrNot} from '../JobCardHelper/JobCardHelper';
import {ScheduleAggregation, JobDetail} from '../../common/types';
import {RemoteLocation} from '../../common/constant';
import {EnUsRoleProperty} from '../../common/filterOptions/constants/filterOption-constants';

export const getAggregationBonusText = (scheduleAggregation: ScheduleAggregation) => {
  const {maxBonus, maxSurgePay, currencyCode} = scheduleAggregation;

  const currency = getCurrencyFromCurrencyCode(currencyCode);

  if (!(maxBonus || maxSurgePay) || !currency) return null;

  if (maxBonus) return `Sign on Bonus up to ${currency}${maxBonus}`;
  if (maxSurgePay) return `Premium Pay up to ${currency}${maxSurgePay} per hour.`;
};

export const getJobDetailWorkAddress = (
  jobDetail: JobDetail,
  scheduleAggregation: ScheduleAggregation,
): string | null => {
  const {fullAddress, locationDescription, jobContainerJobMetaL1, dataSource, virtualLocation} = jobDetail;

  if (isRemoteJob(dataSource, virtualLocation, jobContainerJobMetaL1)) return RemoteLocation;
  if (isSalesForceJobOrNot(dataSource)) return fullAddress;

  let addressInfo: string;

  // If scheduleAggregation has city, then it means all schedules are in the same site.
  // The first schedule's city and state will be used for work address.
  if (scheduleAggregation && scheduleAggregation.city && scheduleAggregation.address) {
    addressInfo = `${scheduleAggregation.address}, ${scheduleAggregation.city}${
      scheduleAggregation.state ? ', ' + scheduleAggregation.state : ''
    }`.trim();
  } else {
    // If scheduleAggregation has no city, then it means that schedules are from multiple cities,
    // then use locationName as work address if present.
    addressInfo = locationDescription || '';
  }

  return addressInfo;
};
const CustomerServiceRoles = [
  EnUsRoleProperty.CUSTOMER_SERVICE_ASSOCIATE,
  EnUsRoleProperty.REMOTE_CUSTOMER_SERVICE_ASSOCIATE,
  EnUsRoleProperty.DELIVERY_STATION_CUSTOMER_SERVICE_ASSOCIATE,
];

export const isCustomerServiceJob = (jobTitle: string | null) => {
  if (!jobTitle) return false;

  return CustomerServiceRoles.includes(jobTitle as EnUsRoleProperty);
};
