import {PlacementType, SectionScreenPlacement, Section} from '@amzn/showtime';

import {AtoZSalariedJobsURI, JobSearchPlacementURI} from '../../utils/uriUtils/uriUtils';
import IconJobTabRest from '../../components/Icons/IconJobTabRest';
import IconJobTabActive from '../../components/Icons/IconJobTabActive';

const placement: SectionScreenPlacement = {
  id: Section.Job,
  primaryNav: {
    id: `${Section.Job}-primary`,
    label: 'Jobs',
    defaultStateIcon: IconJobTabRest,
    activeStateIcon: IconJobTabActive,
    priority: 200,
    uri: `app://${Section.Job}-primary`,
  },
  uri: `app://${Section.Job}-primary`,
  title: 'Jobs',
  type: PlacementType.SectionScreen,
  subsectionPriorityFunc: () => [JobSearchPlacementURI.id, AtoZSalariedJobsURI.id],
};

export default placement;
