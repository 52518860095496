import {PlacementType, SubsectionScreenPlacement, Section} from '@amzn/showtime';

import withProviders from '../Wrapper';
import {getUri, getUriRegexString, JobSearchPlacementURI} from '../../utils/uriUtils/uriUtils';

import JobSearchSubsection from './JobSearchSubsection';

const placement: SubsectionScreenPlacement = {
  component: withProviders(JobSearchSubsection),
  id: JobSearchPlacementURI.id,
  title: 'Level 1-2',
  type: PlacementType.SubsectionScreen,
  sectionId: Section.Job,
  initialUri: getUri(JobSearchPlacementURI),
  uri: getUriRegexString(JobSearchPlacementURI),
};

export default placement;
