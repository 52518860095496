import {GeoUnit} from './constant';
import {HoursPerWeekText, StartDateOptionValue} from './filterOptions/constants/filterOption-constants';

export type NumberPair = [number, number];

export interface BaseOptionType<T> {
  value: T | null;
  shownValue: string;
}

export interface GeoInfo {
  country: string | null;
  postalCode: string | null;
  lng: number | null;
  lat: number | null;
  label?: string | null;
  municipality?: string | null;
  region?: string | null;
  subRegion?: string | null;
  addressNumber?: string | null;
  key?: string | null;
  customPostal?: string | null;
  shownValue?: string | null;
}

export interface CommonJobInfo {
  jobId: string;
  language: string;
  requisitionType: string | null;
  jobTitle: string | null;
  jobType: string | null;
  employmentType: string | null;
  city: string | null;
  state: string | null;
  locationName: string | null;
  postalCode: string | null;
  tagLine: string | null;
  distance: number | null;
  image: string | null;
  jobPreviewVideo: string | null;
  currencyCode: string | null;
  geoClusterDescription: string | null;
  jobContainerJobMetaL1?: string[] | null;
  virtualLocation?: string | null;
  poolingEnabled?: boolean | null;
}

export interface JobCard extends CommonJobInfo {
  dataSource: string | null;
  totalPayRateMin: number;
  totalPayRateMax: number;
  bannerText: string | null;
  featuredJob: boolean | null;
  bonusJob: boolean | null;
  bonusPay: number | null;
  scheduleCount: number | null;
  surgePay: number | null;
  jobTypeL10N: string | null;
  employmentTypeL10N: string | null;
  bonusPayL10N: string | null;
  surgePayL10N: string | null;
  totalPayRateMinL10N: string | null;
  totalPayRateMaxL10N: string | null;
  distanceL10N: string | null;
  monthlyBasePayMin?: number | null;
  monthlyBasePayMax?: number | null;
  monthlyBasePayMinL10N?: string | null;
  monthlyBasePayMaxL10N?: string | null;
}

export interface JobDetail extends CommonJobInfo {
  agencyName: string | null;
  dataSource: string;
  jobIdNumber: string | null;
  jobRole: string | null;
  jobTypeL10N?: string | null;
  employmentTypeL10N?: string | null;
  fullAddress: string | null;
  country: string | null;
  totalPayRateMin: number | null;
  totalPayRateMinL10N?: string | null;
  totalPayRateMax: number | null;
  totalPayRateMaxL10N?: string | null;
  featuredJob: boolean;
  bonusJob: boolean;
  jobDescription: string | null;
  jobQualification: string | null;
  blackbirdPortalURL: string | null;
  careerPortalUrl: string | null;
  postingStatus: string | null;
  uiPath: string | null;
  siteId: string[] | null;
  locationDescription: string | null;
  jobBannerText: string | null;
  bonusPayL10N?: string | null;
  geoClusterId: string | null;
  geoClusterName: string | null;
  geoClusterRegion: string | null;
  geoClusterZone: string | null;
  locationCode: string | null;
  complianceText: string | null;
  requiredLanguage?: string[] | null;
  monthlyBasePay?: number | null;
  dspEnabled: boolean | null;
  hoursPerWeek?: number | null;
  basePayRate?: number | null;
  reqMonthlyPay?: number | null;
  mostRecentPostedDate?: string | null;
  mostRecentUnpostedDate?: string | null;
  address?: string | null;
}

export type ScheduleCards = ScheduleCard[];

export interface ScheduleCard {
  hireStartDate: string | null;
  scheduleId: string;
  jobId: string;
  bonusSchedule: boolean;
  dataSource: string;
  language: string;
  basePay: number;
  featuredSchedule: boolean;
  externalJobTitle: string | null;
  currencyCode: string | null;
  scheduleText: string | null;
  hoursPerWeek: number | null;
  firstDayOnSite: string | null;
  scheduleBannerText: string | null;
  scheduleType: string | null;
  employmentType: string | null;
  tagLine: string | null;
  image: string | null;
  jobPreviewVideo: string | null;
  address: string | null;
  city: string | null;
  state: string | null;
  signOnBonus: number | null;
  postalCode: string | null;
  distance: number | null;
  priorityRank: number | null;
  surgePay: number | null;
  geoClusterId: string | null;
  geoClusterName: string | null;
  siteId: string | null;
  scheduleBusinessCategory: string | null;
  totalPayRate: number | null;
  financeWeekStartDate: string | null;
  laborDemandAvailableCount: number | null;
  scheduleBusinessCategoryL10N: string | null;
  firstDayOnSiteL10N: string | null;
  financeWeekStartDateL10N: string | null;
  scheduleTypeL10N: string | null;
  employmentTypeL10N: string | null;
  basePayL10N: string | null;
  signOnBonusL10N: string | null;
  totalPayRateL10N: string | null;
  distanceL10N: string | null;
  requiredLanguage: string | null;
  monthlyBasePay: number | null;
  monthlyBasePayL10N: string | null;
  vendorKamId: string | null;
  vendorKamName: string | null;
  vendorId: string | null;
  vendorName: string | null;
  kamPhone: string | null;
  kamCorrespondenceEmail: string | null;
  kamStreet: string | null;
  kamCity: string | null;
  kamDistrict: string | null;
  kamState: string | null;
  kamCountry: string | null;
  kamPostalCode: string | null;
}

export interface ScheduleAggregation {
  scheduleCount?: number;
  minPayRate?: number | null;
  maxPayRate?: number | null;
  jobTypes?: string | null;
  employmentTypes?: string | null;
  jobTypesL10N?: string | null;
  employmentTypesL10N?: string | null;
  currencyCode?: string | null;
  maxBonus?: number;
  maxBonusL10n?: string;
  maxSurgePay?: number;
  city?: string | null;
  state?: string | null;
  postalCode?: string | null;
  address?: string | null;
  requiredLanguages?: string[] | null;
  monthlyBasePay?: number | null;
  basePay?: number | null;
}

export interface FilterItemNumber extends BaseOptionType<number> {
  unit?: GeoUnit;
}

export type SortItemString = {
  value: null | string;
  shownValue: string;
};

export type FilterItemString = {
  value: null | string;
  shownValue: string;
  shortValue?: string;
  longValue?: string;
  selectedAll?: boolean;
};

export type JobCards = JobCard[];

export interface JobCardsWithNextToken {
  jobCards: JobCards;
  nextToken: string | null;
}

export interface PayRateConfig {
  initPayRate: NumberPair;
  currencyCode: string;
  showLabel: boolean;
  step: number;
  min: number;
  max: number;
  defaultValue: NumberPair;
  value: NumberPair;
  marks: any;
}

export interface WorkHoursConfig {
  initHoursPerWeek: NumberPair;
  summaryText: WorkHourSummaryConfig;
  showLabel: boolean;
  step: number;
  min: number;
  max: number;
  defaultValue: NumberPair;
  value: NumberPair;
  marks: any;
}

export type WorkHourSummaryConfig = WorkHourSummaryConfigItem[];
export type WorkHourSummaryConfigItem = [WorkHourSummaryMinMax, HoursPerWeekText];
export type WorkHourSummaryMinMax = [number?, number?];
export type StartDateOptionType = BaseOptionType<StartDateOptionValue>;

export enum CurrencyCode {
  GBP = 'GBP',
  USD = 'USD',
  CAD = 'CAD',
  MXN = 'MXN',
  JPY = 'JPY',
  PHP = 'PHP',
  INR = 'INR',
  EUR = 'EUR',
  ZAR = 'ZAR',
}

export interface JobRoleDisplayNameMapType {
  [key: string]: {
    displayValue: string;
    translationKey: string;
  };
}

export enum DataSource {
  SalesForce = 'SALESFORCE',
  DragonStone = 'DRAGONSTONE',
}

export enum PayRateType {
  hourMin = 'hourMin',
  hourMax = 'hourMax',
  monthMin = 'monthMin',
  monthMax = 'monthMax',
}

export enum jobContainerJobMeta {
  VIRTUAL = 'Virtual',
}
